import { createSlice } from "@reduxjs/toolkit";
import { QUESTION_TYPES } from "../../Constants/questionTypes";
// import type { PayloadAction } from "@reduxjs/toolkit";

interface IField {
  id: string;
  type: typeof QUESTION_TYPES.SHORT_TEXT;
  label: string;
  validation: {
    required?: boolean;
  };
  title: string;
}

export interface IForm {
  id: string;
  name: string;
  workspace: {
    name?: string;
    link?: string;
    _id?: String;
  };
  url_id: string;
  type: string;
  owner_id?: string;
  created_at?: string;
  last_updated_at?: string;
  primary_color: string;
  text_size?: "small" | "medium" | "large";
  theme?: {
    primary_color: string;
    secondary_color: string;
    button_color: string;
    button_size: string;
    button_text_color: string;
    text_size: string;
    text_color: string;
    background_color: string;
    font_family: string;
    font: string;
    name: string;
    visibility: string;
    logo: {
      _id: string;
      type: string;
      link: string;
      properties: {
        description: string;
        focal_point: {
          x: number;
          y: number;
        };
      };
    };
    rounded_corners: string;
    _id: string;
    created_at: string;
    updated_at: string;
    owner_id: string;
  };
  secondary_color?: string;
  welcomeScreen: {
    title: string;
    type: string;
    description?: string;
    button_text?: string;
    layout?: {
      type: string;
      attachment?: {
        type: string;
        href?: string;
      };
      placement?: string;
    };
  };
  endScreen: {
    title: string;
    type: string;
    description?: string;
  };
  fields: IField[];
  settings: {
    language: "en";
    progress_bar: "percentage";
    meta: {
      title: "Bootcamp Application Form";
      allow_indexing: true;
    };
    hide_navigation: false;
    is_public: true;
    is_trial: false;
    show_progress_bar: true;
    show_typeform_branding: true;
    are_uploads_public: false;
    show_time_to_complete: false;
    show_number_of_submissions: true;
    show_cookie_consent: false;
    show_question_number: true;
    show_key_hint_on_choices: true;
    autosave_progress: true;
    free_form_navigation: false;
  };
  hidden?: [];
  links?: {
    display: string;
    responses: string;
  };
}

export interface ITheme {
  primary_color: string;
  secondary_color: string;
  text_color: string;
  background_color: string;
  font_family: string;
  font: string;
  name: string;
  button_color: string;
  button_size: "small" | "medium" | "large"; // Based on the "large" value provided
  button_text_color: string;
  text_size: "small" | "medium" | "large"; // Based on the "medium" value provided
  visibility: "private" | "public"; // Assuming the visibility is a string that represents privacy
  logo: {
    _id: string;
    type: "image"; // Assuming this will always be "image"
    link: string;
    properties: {
      description: string;
      focal_point: {
        x: number;
        y: number;
      };
    };
  };
  rounded_corners: "small" | "medium" | "large"; // Based on the "large" value provided
  _id: string;
  created_at: string; // Assuming this is an ISO date string
  updated_at: string; // Assuming this is an ISO date string
  owner_id: string;
}

export interface ICounterState {
  forms: IForm[];
  responses: any[];
}

const initialState: ICounterState = {
  forms: [],
  responses: [],
};

export const counterSlice = createSlice({
  name: "forms",
  initialState,
  reducers: {
    updateForm: (state, action) => {
      const { id } = action.payload;
      const formIndex = state.forms.findIndex((form) => form.id === id);
      state.forms[formIndex] = action.payload;
    },
    addNewForm: (state, action) => {
      state.forms.push(action.payload);
    },
    removeForm: (state, action) => {
      const { id } = action.payload;
      state.forms = state.forms.filter((form) => form.id !== id);
    },
    // incrementByAmount: (state, action: PayloadAction<number>) => {
    //   state.value += action.payload
    // },

    setFormList: (state, action) => {
      state.forms = action.payload;
    },
    setFormResponse: (state, action) => {
      state.responses = action.payload;
    },
    removeFormList: (state) => {
      state.forms = [];
    },
  },
});

export const {
  updateForm,
  addNewForm,
  removeForm,
  setFormList,
  setFormResponse,
  removeFormList,
} = counterSlice.actions;

export default counterSlice.reducer;
