export const dashSeperatedToHumanReadble = (string) => {
    const wordsList = string.replace("_", "-").split("-");
  
    for (let i = 0; i < wordsList.length; i++) {
      wordsList[i] = wordsList[i][0].toUpperCase() + wordsList[i].substr(1);
    }
  
    const str = wordsList.join(" ");
    return str;
  };
  

   export const capitalize = (string) => {
    return string[0].toUpperCase() + string.substr(1);
   }

   export const randomString = (length) => {
      return new Date().getTime().toString(36).substring(0, length);
   }

   export const getLetterForIndex = (index) => {
      return String.fromCharCode(65 + index);
   }

   export const getIndexForLetter = (letter) => {
      return letter.charCodeAt(0) - 65;
   }