import { AutoComplete, Select, Flex, Typography, Popover, Button } from "antd";
import React, {
  useRef,
  useImperativeHandle,
  useState,
  useEffect,
  createRef,
} from "react";
import { Input } from "antd";
import {
  answerCheckboxTextSize,
  getInputStyle,
  getSelectOptionBackgroundColor,
  getSelectOptionBorderColor,
} from "../../../Constants/styles";
import { useFormContext } from "../../../Contexts/FormContext";
import { CountryEmoji } from "../../../Utils/countries";
import { getCountryCallingCode, getCountries } from "react-phone-number-input";
import { useCurrentCountry } from "../../../Api/useCurrentCountry";
import { motion } from "framer-motion";
import { CaretDownFilled } from "@ant-design/icons";
// import DownIcon from "../../../assets/images/downIcon.png";

const countryCodes = getCountries().reduce(
  (o, key) => Object.assign(o, { [key]: `+${getCountryCallingCode(key)}` }),
  {}
);

export const CountrySelect = React.forwardRef(
  ({ country, setCountry, ...props }, ref) => {
    const { userCountryCode: countryCode } = useCurrentCountry();
    const [focusInput, setFocusInput] = useState(false);
    const { styles } = useFormContext();

    useEffect(() => {
      if (countryCode && countryCode != "XX") {
        setCountry(countryCode);
      }
    }, [countryCode]);

    // console.log({ countryCodes });

    const selectRef = useRef(null);
    const [inputValue, setValue] = useState("");
    // console.log({ props });

    const f_options = props.options.filter((o) => o.value);

    const options = !inputValue
      ? f_options
      : f_options.filter(
          (o) =>
            o?.label?.toUpperCase().indexOf(inputValue?.toUpperCase()) !== -1
        );

    const countries = useImperativeHandle(
      ref,
      () => {
        return selectRef.current.input;
      },
      []
    );

    return (
      <>
        <style>
          {`.ant-select-selector {
            padding-left: 0px !important;
          }`}
        </style>
        <Select
          suffixIcon={<></>}
          popupMatchSelectWidth={false}
          // value={}
          // {...props}
          onChange={(value) => {
            setCountry(value);
          }}
          onDropdownVisibleChange={(open) => {
            setFocusInput(open);
          }}
          // open={true}
          // size={"large"}
          value={country || "rc-index-key-0"}
          ref={selectRef}
          variant="borderless"
          options={options}
          optionRender={Option}
          style={{ width: "auto" }}
          labelRender={(value) => {
            console.log({ value });
            return (
              <Flex
                style={{
                  alignItems: "center",
                  // backgroundColor: "yellow"
                }}
                gap={7}
              >
                <Flex
                  style={{
                    alignItems: "center",
                    // backgroundColor: "green"
                  }}
                  gap={7}
                >
                  <div style={{ fontSize: 35, marginTop: "5px" }}>
                    {<CountryEmoji code={value.key} />}
                  </div>
                  <CaretDownFilled
                    style={{ color: styles.secondary_color, marginTop: "5px" }}
                  />
                  {/* <img
                  src={DownIcon}
                  width={15}
                  height={10}
                ></img> */}
                  {/* <DownOutlined
                  style={{
                    color: styles.secondary_color,
                    fontWeight: "bold",
                    marginLeft: 10,
                    fontSize: 20,
                  }}
                /> */}
                </Flex>
              </Flex>
            );
          }}
          dropdownRender={(menu) => {
            console.log("dropdown render", menu, { focusInput });
            return (
              <DropDown
                value={inputValue}
                setValue={setValue}
                focus={focusInput}
              >
                {menu}
              </DropDown>
            );
          }}
        />
        {/* <AutoComplete
        popupMatchSelectWidth={false}
        {...props}
        ref={selectRef}
        variant="borderless"
        optionRender={Option}
        dropdownRender={(menu) => {
          return (
            <DropDown value={inputValue} setValue={setValue}>
              {menu}
            </DropDown>
          );
        }}
        filterOption={(inputValue, option) => {
          // console.log({ inputValue, option });
          return (
            option?.label?.toUpperCase().indexOf(inputValue?.toUpperCase()) !==
            -1
          );
        }}
      /> */}
      </>
    );
  }
);

const Option = (option) => {
  const { styles } = useFormContext();
  const color = styles.secondary_color;
  //   console.log("render option", option);

  return (
    <motion.div key={option.key} layoutScroll>
      <Flex
        style={{
          border: `1px solid #fff`,
          borderWidth: 1,
          borderColor: getSelectOptionBorderColor(color),
          borderRadius: 5,
          padding: "2px 10px 2px 10px",
          // width: "100%",
          // height: "100%",
          alignItems: "center",
          backgroundColor: getSelectOptionBackgroundColor(color),
          // backgroundColor: "red",
          justifyContent: "space-between",
        }}
        gap={"large"}
      >
        <Flex style={{ alignItems: "center" }} gap={"middle"}>
          <div style={{ fontSize: 30 }}>
            {<CountryEmoji code={option.key} />}
          </div>
          <Typography.Text
            style={{
              textAlign: "justify",
              color: color,
              fontSize: answerCheckboxTextSize,
            }}
          >
            {option.label}
          </Typography.Text>
        </Flex>
        <Typography.Text
          style={{
            textAlign: "justify",
            color: color,
            fontSize: answerCheckboxTextSize,
          }}
        >
          {countryCodes[option.key]}
        </Typography.Text>
      </Flex>
    </motion.div>
  );
};

export const DropDown = ({ children, value, setValue, focus }) => {
  const inputRef = useRef(null);
  useEffect(() => {
    if (focus) {
      setTimeout(() => {
        inputRef.current.focus();
      }, 500);
    } else {
      setF(false);
    }
  }, [focus]);
  const { styles } = useFormContext();
  console.log({ focus });
  return (
    <Flex vertical>
      <div style={{ paddingInline: "15px" }}>
        <Input
          ref={inputRef}
          style={{ ...getInputStyle(styles, true) }}
          size="large"
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          onFocus={() => {
            console.log("focus");
          }}
          onBlur={() => {
            console.log("blur");
          }}
          placeholder="Search country"
        />
      </div>
      {children}
    </Flex>
  );
};

// export const CountrySelect = ({
//   //   onChange,
//   //   value,
//   options,
//   country,
//   setCountry,
//   ...props
// }) => {
//   const [inputValue, setValue] = useState(country);
//   const [countrySelectOpen, setCountrySelectOpen] = useState(!!country);
//   //   const { options } = props;
//   console.log({ props, country });

//   const _options = options?.map((o) => ({
//     ...o,
//     key: o?.value,
//     value: o?.label,
//   }));

//   const _onChange = (value) => {
//     console.log("ON change");
//     // onChange(value);
//   };

//   console.log({ options });

//   return (
//     <>
//       {/* {JSON.stringify(value)} */}
//       <AutoComplete
//         {...props}
//         style={{ width: "100px" }}
//         open={countrySelectOpen}
//         labelInValue
//         popupMatchSelectWidth={false}
//         options={_options}
//         onClick={() => {
//           console.log("on focus");
//           setCountrySelectOpen(true);
//         }}
//         value={inputValue}
//         // variant="borderless"
//         onSelect={(value) => {
//           console.log("ON select", value);
//           setCountrySelectOpen(false);
//           setCountry(value.key);
//           //   _onChange(value.key);
//         }}
//         // labelRender={(value) => {
//         //   console.log("label render", value);
//         //   return <div style={{ backgroundColor: "red" }}>{"test"}</div>;
//         // }}
//         onChange={(value) => {
//           console.log("ON change", value);
//           setValue(value.value);
//         }}
//         emptyText={"No data found"}
//         notFoundContent={() => {
//           return (
//             <Input
//               value={inputValue}
//               onChange={(e) => {
//                 setValue(e.target.value);
//               }}
//             ></Input>
//           );
//         }}
//         dropdownRender={(menu) => {
//           return (
//             <DropDown value={inputValue} setValue={setValue}>
//               {menu}
//             </DropDown>
//           );
//         }}
//         optionRender={Option}
//         filterOption={(inputValue, option) => {
//           // console.log({ inputValue, option });
//           return (
//             option?.label?.toUpperCase().indexOf(inputValue?.toUpperCase()) !==
//             -1
//           );
//         }}
//       />
//     </>
//   );
// };
