import { createContext, useState, useContext, useEffect } from "react";
import { SETTING_CONTEXT } from "../Constants/questionTypes.js";
import { updateForm } from "../Redux/slices/formSlice.tsx";
import { useDispatch } from "react-redux";
import { useMutation } from "@tanstack/react-query";
import { useApi } from "../Api/useApi.ts";
import { message } from "antd";
const FormEditorContext = createContext();
import { convertFormObject } from "../Components/MyEditor/FormObjectConfig.jsx";
import { useFormContext } from "./FormContext.jsx";

export const FormEditorProvider = ({ children }) => {
  const dispatch = useDispatch();

  const [form, setForm] = useState({}); //used to save global form details
  const [changed, setChanged] = useState(false);
  const [themeChanged, setThemeChanged] = useState(false);
  const [fields, setFields] = useState([]); //used to store data about fields
  const [welcomeScreen, setWelcomeScreen] = useState({}); //used to store data about welcome screen
  const [selectedField, _setSelectedField] = useState("");
  const [settingsContext, setSettingsContext] = useState(SETTING_CONTEXT.FIELD);
  const [editorField, setEditorField] = useState(null);
  const [endScreen, setEndScreen] = useState({});
  const [selectedWorkSpace, setSelectedWorkSpace] = useState("");
  const [isPublished, setIsPublished] = useState(false);
  const [theme, setTheme] = useState({});
  const [name, setName] = useState(null);

  const { data, setData } = useFormContext();

  useEffect(() => {
    setData({ ...data, fields: fields });
  }, [fields]);

  const setSelectedField = (field) => {
    setEditorField(field);
    _setSelectedField(field);
  };

  useEffect(() => {
    if (form) {
      setFields(form?.fields || []);
      setWelcomeScreen(form?.welcomeScreen || {});
      setEndScreen(form?.endScreen || {});
      setIsPublished(form?.url_id ? true : false);
      setTheme(form?.theme || {});
      setName(form?.name || form?.title || "");
    }
  }, [form]);

  const addField = (field) => {
    setFields([...fields, field]);
    setChanged(true);
  };

  /* form update endpoint handling  */
  const [messageApi, contextHolder] = message.useMessage();
  const api = useApi();

  const updateMutation = useMutation({
    mutationFn: (form) => api.forms.updateForm(form?.url_id, form),
    onSuccess: () => {
      messageApi.success("Form updated successfully");
      setChanged(false);
    },
    onError: (error) => {
      if (error.response) {
        messageApi.error(`${error.response.data.detail[0].msg} `);
      } else {
        messageApi.error("Could not update form: An unexpected error occurred");
      }
      console.error("Error details:", error);
    },
  });

  const saveChanges = () => {
    const updatedForm = { ...form, fields, welcomeScreen, endScreen, name };
    dispatch(updateForm(updatedForm));
    const newObj = convertFormObject(updatedForm);
    updateMutation.mutate(newObj);

    //console.log(newObj);
  };

  return (
    <>
      {contextHolder}
      <FormEditorContext.Provider
        value={{
          form,
          setForm,
          welcomeScreen,
          setWelcomeScreen,
          fields,
          setFields,
          addField,
          selectedField,
          setSelectedField,
          settingsContext,
          setSettingsContext,
          saveChanges,
          editorField,
          setEditorField,
          endScreen,
          setEndScreen,
          selectedWorkSpace,
          setSelectedWorkSpace,
          theme,
          setTheme,
          name,
          setName,
          changed,
          setChanged,
          themeChanged,
          setThemeChanged,
        }}
      >
        {children}
      </FormEditorContext.Provider>
    </>
  );
};

export const useFormEditorContext = () => {
  const context = useContext(FormEditorContext);
  if (!context) {
    throw new Error("useFormEditorContext must be used within a FormProvider");
  }
  return context;
};
