import React from "react";
import { ThunderboltOutlined } from "@ant-design/icons";

const CustomBilling = () => {
  return (
    <div className="grid w-full grid-cols-2 gap-3 px-2 py-4 ">
      <div className="p-4 px-10 text-white bg-black rounded-[48px] ">
        <div className="flex items-center gap-1 ">
          <ThunderboltOutlined />
          <p className="text-lg font-bold">PRO</p>
        </div>
        <p className="text-[10px] ">
          All the features you need to use BuildForm like a Pro.
        </p>
      </div>
      <div className="p-4 px-6 text-black flex flex-col justify-center items-center bg-[#F7F7F7] rounded-[48px] ">
        <p className="text-lg font-bold">Need More ?</p>
        <p className="mt-3 text-[12px] px-6 text-center  font-medium">
          Ask us about volume discounting
        </p>

        <button className="px-2 py-2 mt-4 font-semibold text-white bg-black hover:bg-slate-900 rounded-3xl">
          Let's Talk
        </button>
      </div>
    </div>
  );
};

export default CustomBilling;
