import React, { useState } from "react";
import { AreaChart, Area, Tooltip, ResponsiveContainer } from "recharts";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Menu } from "antd";
import { useQuery } from "@tanstack/react-query";
import { useApi } from "../../Api/useApi.ts";

const items = [
  {
    key: "1",
    label: "Past 7 days",
    value: "7_days",
  },
  {
    key: "2",
    label: "Past 14 days",
    value: "14_days",
  },
  {
    key: "3",
    label: "Past 1 month",
    value: "1_month",
  },
  {
    key: "4",
    label: "Past 3 months",
    value: "3_months",
  },
  {
    key: "5",
    label: "Past 6 months",
    value: "6_months",
  },
  {
    key: "6",
    label: "Past 1 year",
    value: "1_year",
  },
];

const FormRepliesCard = () => {
  const [selectedDays, setSelectedDays] = useState("1");
  const sessionWorkspace = sessionStorage.getItem("selectedWorkSpace");
  const [tooltipData, setTooltipData] = useState(null);

  const handleMouseMove = (e) => {
    if (e && e.activePayload && e.activePayload.length > 0) {
      setTooltipData(e.activePayload[0].payload);
    }
  };

  const handleMouseLeave = () => {
    setTooltipData(null);
  };

  const selectedItem = items.find((item) => item.key === selectedDays);

  // Extract the label and value from the selected item
  const selectedLabel = selectedItem?.label || "Selectable";
  const selectedValue = selectedItem?.value || "";

  const handleMenuClick = (e) => {
    setSelectedDays(e.key);
  };

  const api = useApi();

  const {
    data: statData,
    isSuccess,
    isFetching,
  } = useQuery({
    queryKey: ["stats", sessionWorkspace, selectedValue],
    queryFn: () => api.dashboard.getStats(sessionWorkspace, selectedValue),
    staleTime: Infinity,
  });

  const menu = (
    <Menu
      items={items}
      selectable
      selectedKeys={[selectedDays]}
      onClick={handleMenuClick}
    />
  );

  return (
    <div className="flex flex-col items-center gap-6 px-6 py-6 border rounded-2xl lg:col-span-2">
      <div className="flex items-end justify-between w-full">
        <div className="flex items-center gap-2 ">
          <h2 className="text-lg font-semibold tracking-tighter text-start text-zinc-500">
            Form Replies
          </h2>
          <h2 className="text-xl font-semibold">
            {" "}
            {tooltipData ? tooltipData?.responses : statData?.data?.count}
          </h2>
          <div className="flex items-end gap-2"></div>
        </div>
        <Dropdown overlay={menu}>
          <div className=" bg-gray-50 hover:cursor-pointer hover:bg-gray-100 border  rounded-md px-1.5 py-1 flex gap-1.5 items-center text-xs">
            {selectedLabel || "Selectable"}
            <DownOutlined />
          </div>
        </Dropdown>
        {/*   <p className="flex items-center max-w-2xl mt-4 text-sm text-zinc-500 tex-balance">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
            data-slot="icon"
            className="h-3 w-3 mr-1.5"
          >
            <path
              fillRule="evenodd"
              d="M5.75 2a.75.75 0 0 1 .75.75V4h7V2.75a.75.75 0 0 1 1.5 0V4h.25A2.75 2.75 0 0 1 18 6.75v8.5A2.75 2.75 0 0 1 15.25 18H4.75A2.75 2.75 0 0 1 2 15.25v-8.5A2.75 2.75 0 0 1 4.75 4H5V2.75A.75.75 0 0 1 5.75 2Zm-1 5.5c-.69 0-1.25.56-1.25 1.25v6.5c0 .69.56 1.25 1.25 1.25h10.5c.69 0 1.25-.56 1.25-1.25v-6.5c0-.69-.56-1.25-1.25-1.25H4.75Z"
              clipRule="evenodd"
            ></path>
          </svg>
          Replies in the past
          <span className="ml-1 font-semibold">7 days</span>
        </p> */}
      </div>

      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          width={500}
          height={400}
          data={statData?.data?.data}
          margin={{
            top: 10,
            right: 0,
            left: 0,
            bottom: 0,
          }}
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseLeave}
        >
          <Tooltip
            content={
              <div className="flex flex-col text-sm text-gray-700 text-start">
                <p>{tooltipData?.date}</p>
                <p>{tooltipData?.responses}</p>
              </div>
            }
          />

          <Area
            type="monotone"
            dataKey="responses"
            stroke="#4D68EB"
            fill="rgba(136, 132, 216, 0.2)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default FormRepliesCard;
