import { Input } from "antd";
import { useFormEditorContext } from "../../../Contexts/FormEditorContext";
import {
  QuestionViewFormat,
  QuestionViewContentFormat,
} from "../../../Views/QuestionView.tsx";
import { FormNextButton } from "../../Form/FormNextButton.jsx";
import { Question, QuestionFormat } from "../../Form/Question";
import {
  questionTitleStyle,
  questionDescriptionStyle,
} from "../../../Constants/styles";
import AnswerSection from "../../Form/AnswerSection.tsx";

export const QuestionEditor = () => {
  const { editorField, setEditorField, fields, form, theme } =
    useFormEditorContext();

  const updateField = (key, subkey = null, value) => {
    setEditorField((prev) => {
      if (subkey) {
        return {
          ...prev,
          [key]: {
            ...prev[key],
            [subkey]: value,
          },
        };
      } else {
        return {
          ...prev,
          [key]: value,
        };
      }
    });
  };

  if (!editorField) return null; // Render nothing if no field is selected

  const index = fields.findIndex((f) => f.id === editorField.id);
  console.log("index: ", index);

  // console.log("editorField from question editor : ", editorField);

  return (
    <QuestionViewFormat
      index={index}
      secondary_color={form?.primary_color || form?.secondary_color}
      content={
        <QuestionViewContentFormat
          bottomButton={<FormNextButton index={index}>Next</FormNextButton>}
          question={
            <QuestionFormat
              title={
                <Input
                  style={{ ...questionTitleStyle(), color: theme?.text_color }}
                  size="large"
                  variant="borderless"
                  placeholder="Add question title"
                  value={editorField?.title}
                  onChange={(e) => updateField("title", null, e.target.value)}
                />
              }
              description={
                <Input
                  variant="borderless"
                  placeholder="Add question description (optional)"
                  value={editorField?.properties?.description}
                  onChange={(e) =>
                    updateField("properties", "description", e.target.value)
                  }
                  style={{
                    ...questionDescriptionStyle,
                    color: theme?.text_color,
                  }}
                />
              }
              attachment={editorField?.attachment?.link}
              placement={editorField?.attachment?.placement}
              /*  answer={
                editorField?.properties?.fields ? (
                  editorField?.properties?.fields?.map((field, index) => {
                    return (
                      <Question
                        key={field?.id}
                        field={field}
                        isLastQuestion={false}
                        editor={true}
                      />
                    );
                  })
                ) : (
                  <AnswerSection
                    field={editorField}
                    index={index}
                    editor={true}
                  />
                )
              } */
              answer={
                editorField?.properties?.fields?.length > 0 ? (
                  editorField?.properties?.fields?.map((field, index) => (
                    <Question
                      key={field?.id}
                      field={field}
                      isLastQuestion={false}
                      editor={true}
                    />
                  ))
                ) : (
                  <AnswerSection
                    field={editorField}
                    index={index}
                    editor={true}
                  />
                )
              }
            />
          }
        />
      }
    />
  );
};

/* import { Input, Flex } from "antd";
import { questionAnimation } from "../../../Constants/animations";
import { useFormEditorContext } from "../../../Contexts/FormEditorContext";
import { Question, QuestionFormat } from "../../Form/Question";
import { motion } from "framer-motion";
import {
  questionDescriptionStyle,
  questionTitleStyle,
} from "../../../Constants/styles";
import {
  QuestionViewContentFormat,
  QuestionViewFormat,
} from "../../../Views/QuestionView.tsx";
import { FormNextButton } from "../../Form/FormNextButton.jsx";
import { FormHeader, FormHeaderFormat } from "../../Form/FormHeader.jsx";

export const QuestionEditor = ({ selectedField }) => {
  const { setFields, fields, form } = useFormEditorContext();
  const newFields = [...fields];
  const index = newFields?.findIndex((f) => f?.id === selectedField?.id);

  const updateField = (field, key, subkey = null, value) => {
    if (subkey) {
      newFields[index][key] = newFields[index][key] || {};
      newFields[index][key][subkey] = value;
    } else {
      newFields[index][key] = value;
    }
    setFields(newFields);
  };

  console.log("selected field title :  ", selectedField?.title);

  return (
    // <motion.div
    //   key={JSON.stringify(selectedField)}

    // //   style={{backgroundColor: 'red'}}
    //   {...questionAnimation}
    // >
    // <Flex vertical style={{flex: 1, height:'auto'}}>
    //   <FormHeaderFormat
    //     showPercentage={selectedField == "welcome-screen"}
    //     percentage={((fields.length - index) / fields.length) * 100}
    //     strokeColor={"#fefe00"}
    //   />
    <QuestionViewFormat
      index={index}
      secondary_color={form?.secondary_color | form?.primary_color}
      content={
        <QuestionViewContentFormat
          bottomButton={<FormNextButton index={index}>Next</FormNextButton>}
          question={
            <QuestionFormat
              title={
                <Input
                  style={questionTitleStyle()}
                  size="large"
                  variant="borderless"
                  placeholder="Add question title"
                  value={selectedField?.title}
                  onChange={(e) =>
                    updateField(selectedField, "title", null, e.target.value)
                  }
                />
              }
              description={
                <Input
                  variant="borderless"
                  placeholder="Add question description (optional)"
                  value={selectedField?.properties?.description}
                  onChange={(e) =>
                    updateField(
                      selectedField,
                      "properties",
                      "description",
                      e.target.value
                    )
                  }
                  style={questionDescriptionStyle}
                />
              }
              answer={
                <div className="px-4 text-xs text-start text-zinc-500">
                  {" "}
                  answer
                </div>
              }
            />
          }
        />
      }
    />
    // </Flex>
  );
};
 */
