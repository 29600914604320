import { useEffect } from "react";

const useKeyboardShortcut = (keys, callback) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      const { key, metaKey, ctrlKey } = event;
      if (
        (metaKey && keys.includes(`cmd+${key.toLowerCase()}`)) ||
        (ctrlKey && keys.includes(`ctrl+${key.toLowerCase()}`))
      ) {
        event.preventDefault();
        callback(key, event);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [keys, callback]);
};

export default useKeyboardShortcut;
