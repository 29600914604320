import React from "react";
import { useFormEditorContext } from "../../Contexts/FormEditorContext";
import { SiderFieldCard } from "../FormEditor/SiderFieldCard";
import { Divider } from "antd";
import { Reorder } from "framer-motion";
import { AddFieldButton } from "../FormEditor/AddFieldButton";
import { FormDeleteButton } from "../FormEditor/FormEditorSider/FormDeleteButton";
import { FormSaveButton } from "../FormEditor/FormEditorSider/FormSaveButton";
import { sideFade } from "../../Constants/animations";

const ContentTab = () => {
  const { form, fields, setFields, theme } = useFormEditorContext();

  return (
    <>
      <div className="flex flex-col h-full pt-5 pb-4 overflow-y-auto ">
        <div className="flex flex-col items-start justify-start gap-1 mb-1 text-start">
          {/*   <div className="flex items-center gap-2 ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              fill="currentColor"
              aria-hidden="true"
              data-slot="icon"
              className="w-3 h-3 text-zinc-500"
            >
              <path d="M2 3.5A1.5 1.5 0 0 1 3.5 2h2A1.5 1.5 0 0 1 7 3.5v2A1.5 1.5 0 0 1 5.5 7h-2A1.5 1.5 0 0 1 2 5.5v-2ZM2 10.5A1.5 1.5 0 0 1 3.5 9h2A1.5 1.5 0 0 1 7 10.5v2A1.5 1.5 0 0 1 5.5 14h-2A1.5 1.5 0 0 1 2 12.5v-2ZM10.5 2A1.5 1.5 0 0 0 9 3.5v2A1.5 1.5 0 0 0 10.5 7h2A1.5 1.5 0 0 0 14 5.5v-2A1.5 1.5 0 0 0 12.5 2h-2ZM11.5 9a.75.75 0 0 1 .75.75v1h1a.75.75 0 0 1 0 1.5h-1v1a.75.75 0 0 1-1.5 0v-1h-1a.75.75 0 0 1 0-1.5h1v-1A.75.75 0 0 1 11.5 9Z"></path>
            </svg>
            <h4 className="text-sm font-semibold">About</h4>
          </div>
          <div className="text-xs text-zinc-500">
            Describe the purpose of this form. The AI will consider it when
            processing the form.
          </div>
          <div className="bg-white border border-zinc-200 mt-3 rounded-lg resize-none w-full p-1 text-xs focus:outline-none has-[:focus]:ring-1 ring-offset-1 ring-offset-white ring-zinc-600 transition duration-150 leading-normal hover:bg-zinc-100 has-[:focus]:bg-white shadow overflow-hidden">
            <textarea
              id="description"
              rows="1"
              placeholder="Describe what this form is about..."
              className="p-2 px-3 resize-none w-full focus:outline-none bg-transparent max-h-[20rem]"
            >
              {form?.description}
            </textarea>
            <div className="flex items-center gap-1 px-3 py-2 border rounded-lg text-zinc-500 bg-zinc-50 border-zinc-200">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
                data-slot="icon"
                className="w-4 h-4 text-blue-500"
              >
                <path
                  fillRule="evenodd"
                  d="M18 10a8 8 0 1 1-16 0 8 8 0 0 1 16 0Zm-8-5a.75.75 0 0 1 .75.75v4.5a.75.75 0 0 1-1.5 0v-4.5A.75.75 0 0 1 10 5Zm0 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <p className="text-xs">
                Leave blank if you prefer not to add context
              </p>
            </div>
          </div> */}

          {/* steps */}
          <div className="mt-4">
            <div className="flex items-center gap-1 mb-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="currentColor"
                aria-hidden="true"
                data-slot="icon"
                className="w-3 h-3 text-zinc-500"
              >
                <path d="M2 4a2 2 0 0 1 2-2h8a2 2 0 1 1 0 4H4a2 2 0 0 1-2-2ZM2 9.25a.75.75 0 0 1 .75-.75h10.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.25ZM2.75 12.5a.75.75 0 0 0 0 1.5h10.5a.75.75 0 0 0 0-1.5H2.75Z"></path>
              </svg>
              <h4 className="text-sm font-semibold">Steps</h4>
            </div>
            <p className="text-xs text-zinc-500 mb-3.5">
              The steps users will take to complete the form
            </p>
            <div className="flex flex-col w-full gap-2 overflow-y-auto min-h-max">
              <SiderFieldCard
                field={"welcome-screen"}
                label={"Welcome screen"}
              />
              <Reorder.Group
                className="reorder-ul fill-available"
                axis="y"
                values={fields}
                onReorder={setFields}
                //   style={{ gap: "10px" }}
              >
                {fields.map((item) => (
                  <Reorder.Item
                    key={item?.id}
                    value={item}
                    {...sideFade}
                    className="reorder-li fill-available"
                    style={{}}
                  >
                    <SiderFieldCard field={item} />
                  </Reorder.Item>
                ))}
              </Reorder.Group>
              <AddFieldButton />
              <Divider />
              <SiderFieldCard field={"end-screen"} label={"End screen"} />
            </div>
          </div>
        </div>

        <div className="flex gap-2 mt-auto">
          <FormSaveButton />
          <FormDeleteButton />
        </div>
      </div>
    </>
  );
};

export default ContentTab;
