import { useFormEditorContext } from "../../Contexts/FormEditorContext";
const EndScreenSettings = () => {
  const { endScreen, setEndScreen } = useFormEditorContext();

  const updateEndScreen = (key, value) => {
    setEndScreen({
      ...endScreen,
      [key]: value,
    });
  };

  return (
    <div className={`flex gap-2 my-1 text-sm ${"flex-col items-start"}`}>
      <p className="text-sm font-medium">Title</p>
      <input
        className="w-full p-2 border border-gray-300 rounded-md focus:border-black focus:outline-none"
        value={endScreen?.title || ""}
        onChange={(e) => updateEndScreen("title", e.target.value)}
      />
      <p className="text-sm font-medium">Description</p>
      <input
        className="w-full p-2 border border-gray-300 rounded-md focus:border-black focus:outline-none"
        value={endScreen?.description || ""}
        onChange={(e) => updateEndScreen("description", e.target.value)}
      />
    </div>
  );
};

export default EndScreenSettings;
