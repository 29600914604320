export const QUESTION_TYPES = {
  MULTIPLE_CHOICE: "multiple_choice",
  SHORT_TEXT: "short_text",
  EMAIL: "email",
  DROPDOWN: "dropdown",
  PHONE_NUMBER: "phone_number",
  SECTION: "section",
  CONTACT_INFORMATION: "contact_info",
  CHECKBOX: "checkbox",
  MULTIPLE_FIELD: "multiple_field",
  LEGAL: "legal",
  COUNTRY: "country",
};
export const FIELD_TYPE_OPTIONS = {
  [QUESTION_TYPES.MULTIPLE_CHOICE]: {
    type: QUESTION_TYPES.MULTIPLE_CHOICE,
    label: "Multiple Choice",
  },
  [QUESTION_TYPES.SHORT_TEXT]: {
    type: QUESTION_TYPES.SHORT_TEXT,
    label: "Short Text",
  },
  [QUESTION_TYPES.EMAIL]: { type: QUESTION_TYPES.EMAIL, label: "Email" },
  [QUESTION_TYPES.DROPDOWN]: {
    type: QUESTION_TYPES.DROPDOWN,
    label: "Dropdown",
  },
  [QUESTION_TYPES.PHONE_NUMBER]: {
    type: QUESTION_TYPES.PHONE_NUMBER,
    label: "Phone Number",
  },
  [QUESTION_TYPES.SECTION]: {
    type: QUESTION_TYPES.SECTION,
    label: "Section",
  },
  [QUESTION_TYPES.CONTACT_INFORMATION]: {
    type: QUESTION_TYPES.CONTACT_INFORMATION,
    label: "Contact Information",
  },
  /*   [QUESTION_TYPES.CHECKBOX]: {
    type: QUESTION_TYPES.CHECKBOX,
    label: "Checkbox",
  }, */
  [QUESTION_TYPES.LEGAL]: {
    type: QUESTION_TYPES.LEGAL,
    label: "legal",
  },
  [QUESTION_TYPES.COUNTRY]: {
    type: QUESTION_TYPES.COUNTRY,
    label: "country",
  },
};

export const FIELD_TYPE_SETTINGS = {
  [QUESTION_TYPES.SHORT_TEXT]: {
    title: "ShortText",
    properties: {
      description: "ShortText",
    },
    validations: {
      required: "Switch",
    },
    attachment: {
      link: "Image",
      placement: "Placement",
    },
  },
  [QUESTION_TYPES.MULTIPLE_CHOICE]: {
    title: "ShortText",
    properties: {
      description: "ShortText",
      choices: "ArrayInput",
      // randomize: "Switch",
      // alphabetical_order: "Switch",
      allow_multiple_selection: "Switch",
    },
    validations: {
      required: "Switch",
    },
    attachment: {
      link: "Image",
      placement: "Placement",
    },
  },
  [QUESTION_TYPES.EMAIL]: {
    title: "ShortText",
    properties: {
      description: "ShortText",
    },
    validations: {
      required: "Switch",
    },
  },
  [QUESTION_TYPES.PHONE_NUMBER]: {
    title: "ShortText",
    properties: {
      description: "ShortText",
    },
    validations: {
      required: "Switch",
    },
  },
  [QUESTION_TYPES.DROPDOWN]: {
    title: "ShortText",
    properties: {
      description: "ShortText",
      choices: "ArrayInput",
      // randomize: "Switch",
      // alphabetical_order: "Switch",
      // allow_multiple_selection: "Switch",
    },
    validations: {
      required: "Switch",
    },
  },
  [QUESTION_TYPES.SECTION]: {
    title: "ShortText",
    properties: {
      description: "ShortText",
    },
  },
  [QUESTION_TYPES.CONTACT_INFORMATION]: {
    title: "ShortText",
    /*   validations: {
      required: "Switch",
    }, */
    properties: {
      description: "ShortText",
      fields: "ArrayObject",
    },
  },
  [QUESTION_TYPES.CHECKBOX]: {
    title: "ShortText",
    properties: {
      description: "ShortText",
      checkbox_label: "ShortText",
    },
    validations: {
      required: "Switch",
    },
  },
  [QUESTION_TYPES.COUNTRY]: {
    title: "ShortText",
    properties: {
      description: "ShortText",
    },
    validations: {
      required: "Switch",
    },
  },
  [QUESTION_TYPES.LEGAL]: {
    title: "ShortText",
    properties: {
      description: "ShortText",
    },
  },
};
export const SETTING_CONTEXT = {
  FIELD: "Field",
  GLOBAL: "Global",
};
