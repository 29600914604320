import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";
import { sideFade } from "../../Constants/animations";
import { updateForm } from "../../Redux/slices/formSlice.tsx";
import { useFormEditorContext } from "../../Contexts/FormEditorContext";

const FormSettingsSider = ({ formSettingsOpen, setFormSettingsOpen }) => {
  const { form, name, setName, setChanged } = useFormEditorContext();
  const dispatch = useDispatch();

  const updateFormName = (value) => {
    setName(value);
    setChanged(true);
  };

  const handleSave = () => {
    const updated = { ...form, name };
    dispatch(updateForm(updated));
    setFormSettingsOpen(!formSettingsOpen);
  };

  return (
    <main className="relative h-screen overflow-y-auto">
      <AnimatePresence mode="wait">
        <motion.div {...sideFade}>
          <div className="w-[20rem] h-full bg-white px-5 pt-4 flex flex-col relative">
            {/* breadcrumb */}

            <div className="flex items-center justify-between mb-6">
              <div className="flex flex-col ">
                <div className="flex items-center gap-2 ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                    data-slot="icon"
                    class="h-4 w-4"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M7.84 1.804A1 1 0 0 1 8.82 1h2.36a1 1 0 0 1 .98.804l.331 1.652a6.993 6.993 0 0 1 1.929 1.115l1.598-.54a1 1 0 0 1 1.186.447l1.18 2.044a1 1 0 0 1-.205 1.251l-1.267 1.113a7.047 7.047 0 0 1 0 2.228l1.267 1.113a1 1 0 0 1 .206 1.25l-1.18 2.045a1 1 0 0 1-1.187.447l-1.598-.54a6.993 6.993 0 0 1-1.929 1.115l-.33 1.652a1 1 0 0 1-.98.804H8.82a1 1 0 0 1-.98-.804l-.331-1.652a6.993 6.993 0 0 1-1.929-1.115l-1.598.54a1 1 0 0 1-1.186-.447l-1.18-2.044a1 1 0 0 1 .205-1.251l1.267-1.114a7.05 7.05 0 0 1 0-2.227L1.821 7.773a1 1 0 0 1-.206-1.25l1.18-2.045a1 1 0 0 1 1.187-.447l1.598.54A6.992 6.992 0 0 1 7.51 3.456l.33-1.652ZM10 13a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <h4 className="text-sm font-semibold">Settings</h4>
                </div>
                <p className="text-xs font-medium text-gray-400 text-start">
                  Global configurations
                </p>
              </div>
              <button
                className="p-2 transition-all duration-200 ease-in-out bg-white border border-gray-300 rounded-lg ring-black border-1 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50 animate-scale-up-sm"
                onClick={handleSave}
              >
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 16 17"
                  fill="#737373"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M5.59646 8.5L0 2.90354L2.40354 0.5L8 6.09646L13.5965 0.5L16 2.90354L10.4035 8.5L16 14.0965L13.5965 16.5L8 10.9035L2.40354 16.5L0 14.0965L5.59646 8.5Z"
                  ></path>
                </svg>
              </button>
            </div>

            {/* Form name */}
            <div className=" flex flex-col gap-2.5">
              <div className="flex flex-col gap-2 ">
                <label className="text-sm font-medium text-start">
                  Form name
                </label>
                <input
                  className="w-full p-2 border border-gray-300 rounded-md focus:border-black focus:outline-none"
                  value={name}
                  required
                  variant="outlined"
                  onChange={(e) => updateFormName(e.target.value)}
                />
              </div>
            </div>

            {/* Buttons - save and discard  */}
            <div className="flex items-center justify-center w-full gap-4 mt-6 ">
              <button
                className="whitespace-nowrap ring-offset-white focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-zinc-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50    disabled:bg-zinc-700 bg-black hover:bg-zinc-700 transition duration-150 rounded-lg py-2.5 px-2 w-full text-white text-xs font-medium flex items-center justify-center h-9"
                onClick={() => setFormSettingsOpen(!formSettingsOpen)}
              >
                Save
              </button>
              <button
                class="flex items-center justify-center w-full p-2 text-xs font-medium rounded-lg hover:bg-rose-100 text-rose-500"
                onClick={() => setFormSettingsOpen(!formSettingsOpen)}
              >
                Discard
              </button>
            </div>
          </div>
        </motion.div>
      </AnimatePresence>
    </main>
  );
};

export default FormSettingsSider;
