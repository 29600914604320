import { Typography, List } from "antd";
import gfm from 'remark-gfm';
import Markdown from 'react-markdown'

const { Title, Text, SyntaxHighlighter } = Typography;

const renderers = {
  heading: function Heading(props) {
    return <Title level={props.level}>{props.children}</Title>;
  },
  list: function MakeList(props) {
    return <List bordered>{props.children}</List>;
  },
  listItem: function MakeListItem(props) {
    return <List.Item>{props.children}</List.Item>;
  },
  inlineCode: function makeInlineCode(props) {
    return <Text code>{props.children}</Text>;
  },
  code: function makeCodeBlock(props) {
    return (
      <Text code>{props.value}</Text>
      //   <SyntaxHighlighter language={props.language} style={coy}>
      //     {props.value}
      //   </SyntaxHighlighter>
    );
  },
  blockquote: function makeBlockQuote(props) {
    return <Text type="secondary">{props.children}</Text>;
  },
};

export const MarkdownTypography = ({ children }) => {
    return <Markdown components={renderers} remarkPlugins={[gfm]} children={children} />
};
