import { useQuestionContext } from "../../Contexts/QuestionContext";
import { color, motion } from "framer-motion";
import { IQuestion } from "../../Constants/test2";
import { Input, InputNumber } from "antd";
import { Select } from "antd";
import React, { createRef, useEffect, useState } from "react";
import { Button, Form, Space, AutoComplete, Flex } from "antd";
import { Col, Row } from "antd";
import { QUESTION_TYPES } from "../../Constants/questionTypes";
import { DownOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import { getLetterForIndex } from "../../Utils/string";
import tinycolor from "tinycolor2";
import { useFormContext } from "../../Contexts/FormContext";
import { CheckBoxGroupInput } from "../Basic/CheckBoxGroupInput";
import { PhoneNumberInput } from "../Basic/PhoneNumberInput";
import { isPossiblePhoneNumber } from "react-phone-number-input";

import {
  getInputStyle,
  questionDescriptionStyle,
} from "../../Constants/styles";
import { AutoCompleteDropdown } from "../Basic/AutocompleteDropdown";
import { Checkbox } from "../Basic/Checkbox";

const inputProps = { size: "large" };

const InputWrapper = ({ children, key, keyVal, delay }) => {
  // const [show, setShow] = useState(false);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setShow(true);
  //   }, delay * 1000);
  // }, []);

  // if (!show) return <></>;

  return (
    <motion.div
      key={"fjkds af"}
      initial={{ opacity: 0, translateY: 20 }}
      animate={{ opacity: 1, translateY: 0 }}
      transition={{ delay: 0.2 + delay }}
      style={{
        minWidth: "100%",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
      }}
    >
      {children}
    </motion.div>
  );
};

const CustomInput = ({ Input, onChange, ...props }) => {
  const { setError } = useQuestionContext();
  const { errors } = Form.Item.useStatus();

  const _onChange = (vals) => {
    console.log("onChange");
    setError((error) => ({ ...error, [props.id]: null }));
    onChange(vals);
  };

  useEffect(() => {
    // console.log({ errors });
    if (errors.length > 0) {
      setError((error) => ({ ...error, [props.id]: errors[0] }));
    } else {
      setError((error) => ({ ...error, [props.id]: null }));
    }
  }, [errors]);
  // console.log({ props });
  return (
    <Input
      {...{ ...props, ...inputProps, onChange: _onChange }}
      // style={inputStyle}
      // value={value}
      // onChange={(e) => {
      //   onChange(e.target.value);
      // }}
    />
  );
};

const AnswerSection = ({ field, index, editor }) => {
  const {
    updateAnswers,
    styles,
    formInstance,
    isSubQuestion,
    setIsSubQuestion,
  }: {
    updateAnswers: (
      field: string,
      mainField: string | null,
      value: string | number
    ) => void;
    styles: { [key: string]: string };
    formInstance: any;
    isSubQuestion: [boolean, Number, number];
    setIsSubQuestion: (b: [boolean, Number, number]) => void;
  } = useFormContext();

  const { currentQuestion } = useQuestionContext();

  const [isFocused, setIsFocused] = useState(false);

  const onClick = () => {
    if (isSubQuestion[0]) {
      // updateAnswers(field.id, field.id, index);
      setIsSubQuestion([true, index, isSubQuestion[2]]);
    }
  };

  const onFocus = () => {
    // setIsFocused(true);
    if (isSubQuestion[1] != index) {
      setIsSubQuestion([true, index, isSubQuestion[2]]);
    }
  };

  // const onBlur = () => {
  //   // setIsFocused(false);
  //   setIsSubQuestion([true, index, isSubQuestion[2]]);
  // };

  const commonProps = {
    noStyle: true,
    validateTrigger: ["onSubmit"],
    style: {
      width: "100%",
      backgroundColor: "transparent",
    },
  };

  //console.log("field validation : ", field);
  const commonRules = [
    field?.validations?.required
      ? {
          required: true,
          message: `This field is required`,
        }
      : null,
  ];
  const options = field?.properties?.choices?.map((o) => ({
    ...o,
    value: o?.label,
  }));

  const inputStyle = getInputStyle(styles, isFocused);

  //console.log(field, field?.type);
  switch (field.type) {
    case QUESTION_TYPES.SHORT_TEXT:
      return (
        <Form.Item
          name={field.id}
          {...commonProps}
          rules={[...commonRules]}
          // noStyle
        >
          {/* <Input placeholder="Type here..." style={inputStyle} /> */}
          <CustomInput
            Input={(props) => (
              <Input
                autoFocus={
                  editor ? null : !isSubQuestion[0] || index == isSubQuestion[1]
                }
                onFocus={editor ? null : onFocus}
                // onBlur={onBlur}
                onClick={onClick}
                placeholder="Type here..."
                style={inputStyle}
                {...props}
              />
            )}
          />
        </Form.Item>
      );
    case QUESTION_TYPES.EMAIL:
      return (
        <Form.Item
          name={field.id}
          {...commonProps}
          // required={field?.validation?.required}
          rules={[
            {
              type: "email",
              message: "The input is not valid E-mail!",
            },
            ...commonRules,
          ]}
        >
          <CustomInput
            Input={(props) => (
              <Input
                autoFocus={
                  editor ? null : !isSubQuestion[0] || index == isSubQuestion[1]
                }
                placeholder="Type here..."
                style={inputStyle}
                {...props}
              />
            )}
          />
        </Form.Item>
      );
    case QUESTION_TYPES.DROPDOWN:
      return (
        <Form.Item
          name={field.id}
          {...commonProps}
          rules={[{ enum: [options] }, ...commonRules]}
        >
          {/* <Select>
            {field?.properties?.choices.map((option) => (
              <Select.Option key={option.id} value={option.id}>
                {option.label}
              </Select.Option>
            ))}
          </Select> */}
          <CustomInput
            Input={(props) => (
              <AutoCompleteDropdown
                {...props}
                field={field}
                index={index}
                editor={editor}
              />
            )}
          />
        </Form.Item>
      );
    case QUESTION_TYPES.MULTIPLE_CHOICE:
      return (
        <Form.Item
          name={field.id}
          {...commonProps}
          rules={[...commonRules]}
          validateTrigger={["onChange"]}
        >
          <CustomInput
            Input={(props) => (
              <CheckBoxGroupInput
                {...props}
                field={field}
                index={index}
                editor={editor}
              />
            )}
          />
        </Form.Item>
      );
    case QUESTION_TYPES.PHONE_NUMBER:
      // const prefixSelector = (
      //   <Form.Item name="prefix" noStyle>
      //     <Select style={{ width: 70 }}>
      //       <Select.Option value="94">+94</Select.Option>
      //       <Select.Option value="86">+86</Select.Option>
      //       <Select.Option value="87">+87</Select.Option>
      //     </Select>
      //   </Form.Item>
      // );
      return (
        <Form.Item
          name={field.id}
          {...commonProps}
          rules={[
            // { type: "number", message: "Enter valid phone number (number)" },
            // { len: 9, message: "Enter valid phone number" },
            ...commonRules,
            {
              message: "Enter valid phone number",
              validator: (_, value) => {
                if (isPossiblePhoneNumber(value)) {
                  return Promise.resolve();
                } else {
                  return Promise.reject("Enter valid phone number");
                }
              },
            },
          ]}
          // style={{ backgroundColor: "red" }}

          // rules={}
        >
          <CustomInput
            Input={(props) => (
              <PhoneNumberInput
                {...props}
                field={field}
                autoFocus={
                  editor ? null : !isSubQuestion[0] || index == isSubQuestion[1]
                }
              />
            )}
            // Input={(props) => (
            //   <Input
            //     autoFocus={!isSubQuestion[0] || index == isSubQuestion[1]}
            //     addonBefore={prefixSelector}
            //     // style={{ width: "100%", ...inputStyle }}
            //     {...props}
            //     style={inputStyle}
            //   />
            // )}
          />
        </Form.Item>
      );

    // case "select":
    //   return (
    //     <Select
    //     // onChange={onChange}
    //     >
    //       {/* {field?.options.map((option) => (
    //         <Select.Option key={option} value={option}>
    //           {option}
    //         </Select.Option>
    //       ))} */}
    //     </Select>
    //   );
    case QUESTION_TYPES.SECTION:
      return <div></div>;
    case QUESTION_TYPES.CHECKBOX:
      return (
        <Form.Item
          name={field.id}
          {...commonProps}
          rules={[
            // { type: "number", message: "Enter valid phone number (number)" },
            // { len: 9, message: "Enter valid phone number" },
            ...commonRules,
          ]}
          // style={{ backgroundColor: "red" }}

          // rules={}
        >
          <CustomInput
            Input={(props) => (
              <Checkbox {...props} field={field} editor={editor}>
                {field?.properties?.label}
              </Checkbox>
            )}
          />
        </Form.Item>
      );
    case QUESTION_TYPES.LEGAL:
      return (
        <Form.Item
          name={field.id}
          {...commonProps}
          rules={[...commonRules]}
          validateTrigger={["onChange"]}
        >
          <CustomInput
            Input={(props) => (
              <CheckBoxGroupInput
                {...props}
                field={field}
                index={index}
                editor={editor}
                legalOptions={true} // New prop to handle legal options
              />
            )}
          />
        </Form.Item>
      );

    case QUESTION_TYPES.COUNTRY:
      return (
        <Form.Item
          name={field.id}
          {...commonProps}
          rules={[{ enum: [options] }, ...commonRules]}
        >
          <CustomInput
            Input={(props) => (
              <AutoCompleteDropdown
                {...props}
                field={field}
                index={index}
                editor={editor}
                country={true}
              />
            )}
          />
        </Form.Item>
      );

    default:
      return (
        <div>
          {`${field?.type === "contact_info" ? "" : "Error in Form Object"}`}{" "}
        </div>
      );
  }
};

const AnswerSectionWrapper = ({
  field,
  delay,
  shouldShowNextButton,
  index,
  editor,
}) => {
  const {
    currentQuestionIndex,
    setShowNextButton,
  }: { currentQuestionIndex: number; setShowNextButton: (b: boolean) => void } =
    useFormContext();

  //console.log("field from answer section :", field);

  const { initial } = useQuestionContext();

  // console.log({ delay });

  return (
    <motion.div
      key={"fjkds af"}
      initial={initial ? { opacity: 0, translateY: 20 } : "false"}
      animate={{ opacity: 1, translateY: 0 }}
      transition={{ delay: 0.2 + delay }}
      style={{
        minWidth: "100%",
        alignItems: "center",
        // justifyContent: "center",
        display: "flex",
        // backgroundColor: 'red',
      }}
      onAnimationComplete={
        shouldShowNextButton
          ? () => {
              setShowNextButton(true);
            }
          : () => {}
      }
    >
      <AnswerSection field={field} index={index} editor={editor} />
    </motion.div>
  );
};

export default AnswerSectionWrapper;
