import React from "react";
import { Flex } from "antd";
import {
  FacebookFilled,
  InstagramFilled,
  LinkedinFilled,
  TwitterSquareFilled,
} from "@ant-design/icons";
import { on } from "events";

export const Socials = () => {
  // const size =

  const style = {
    fontSize: "40px",
  };

  const links = {
    facebookLink: "https://www.facebook.com/",
    twitterLink: "https://twitter.com/",
    instagramLink: "https://www.instagram.com/",
    linkedinLink: "https://www.linkedin.com/",
  };

  const onClick = (site) => {
    window.open(site, "_blank");
  };

  return (
    <Flex>
      <FacebookFilled
        style={{ ...style, color: "#1877f2" }}
        onClick={() => onClick(links.facebookLink)}
      />
      <TwitterSquareFilled
        style={{ ...style, color: "#00aced" }}
        onClick={() => onClick(links.twitterLink)}
      />
      <LinkedinFilled
        style={{ ...style, color: "#0077b5" }}
        onClick={() => onClick(links.linkedinLink)}
      />
    </Flex>
  );
};
