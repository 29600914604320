import { Input } from "antd";
import { useFormEditorContext } from "../../../Contexts/FormEditorContext";
import {
  getDynamicTextTitle,
  getDynamicTextDescription,
} from "../../../Constants/styles";
import { WelcomeScreenContainer } from "../../Form/WelcomeScreen";

const WelcomeScreenEditor = () => {
  const { TextArea } = Input;

  const { welcomeScreen, setWelcomeScreen, theme, setChanged } =
    useFormEditorContext();

  const updateWelcomeScreen = (key, value) => {
    setWelcomeScreen({
      ...welcomeScreen,
      [key]: value,
    });
    setChanged(true);
  };

  const hasAttachment =
    welcomeScreen?.attachment?.href || welcomeScreen?.layout?.attachment?.href;
  const hasPlacement = welcomeScreen?.layout?.placement;

  const dynamicTextTitle = getDynamicTextTitle(theme);
  const dynamicTextDescription = getDynamicTextDescription(theme);

  return (
    <WelcomeScreenContainer
      title={
        <div className="w-full ">
          <TextArea
            value={welcomeScreen.title || ""}
            onChange={(e) => {
              updateWelcomeScreen("title", e.target.value);
            }}
            autoSize={{
              minRows: 1,
              maxRows: 5,
            }}
            className="font-bold bg-transparent focus:bg-transparent active:bg-transparent hover:bg-transparent"
            style={{
              color: theme?.text_color,
              fontSize: dynamicTextTitle,
              fontFamily: theme?.font_family,
              padding: 0,
            }}
          />
        </div>
      }
      description={
        <div className="w-full ">
          <TextArea
            value={welcomeScreen.description || ""}
            onChange={(e) => {
              updateWelcomeScreen("description", e.target.value);
            }}
            autoSize={{
              minRows: 1,
              maxRows: 5,
            }}
            style={{
              color: theme?.text_color,
              fontSize: dynamicTextDescription,
              fontFamily: theme?.font_family,
              padding: 0,
            }}
            className="w-full my-1 bg-transparent focus:bg-transparent active:bg-transparent hover:bg-transparent active:border-none"
          />
        </div>
      }
      button_text={welcomeScreen?.button_text}
      layout={welcomeScreen?.layout}
      isEditor={true}
    />
  );
};

export default WelcomeScreenEditor;
