import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { sideFade, sideFadeRight } from "../Constants/animations";

const CommonLayout = ({ children, sidebar, mainContentClassName }) => {
  return (
    <main className="relative h-screen overflow-x-hidden overflow-y-hidden">
      <div className="flex h-full">
        {/* Sidebar section */}
        <AnimatePresence mode="wait">
          <motion.div {...sideFade}>{sidebar}</motion.div>
        </AnimatePresence>

        {/* Main section */}
        <AnimatePresence mode="wait">
          <motion.div
            {...sideFadeRight}
            className={`w-full py-2 pr-2 ${mainContentClassName}`}
          >
            {children}
          </motion.div>
        </AnimatePresence>
      </div>
    </main>
  );
};

export default CommonLayout;
