import { useEffect } from "react";
import { useQuestionContext } from "../Contexts/QuestionContext";
import { useFormContext } from "../Contexts/FormContext";
import _ from "lodash";

export const useEnterPress = (callback) => {
  useOnKeyPress(callback, "Enter");
};

export const useOnKeyPress = (callback, key) => {
  let initial = false;

  const handleKeyPress = (e) => {
    console.log("handle key pressed", e.key);
    if (e.repeat) return;
    if (e.key == key) {
      console.log(key, "pressed");
      callback();
    }
  };

  useEffect(() => {
    if (!initial) {
      initial = true;
      window.addEventListener("keypress", (e) => handleKeyPress(e));
    }
    return () =>
      window.removeEventListener("keypress", (e) => handleKeyPress(e));
  }, []);
};
