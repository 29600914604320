import React from "react";
import { Typography } from "antd";
import SquareAnimatedBackground from "../../Components/Basic/SquareAnimatedBackground/index.jsx";
import Logo from "../../Components/Basic/Logo.jsx";
import { SignUp, SignIn } from "@clerk/clerk-react";

const { Title } = Typography;

export const LoginView = ({ isLogin = true }) => {
  return (
    <div className=" flex h-screen w-full justify-center items-center ">
      {isLogin ? (
        <SignIn signUpUrl="/register" />
      ) : (
        <SignUp signInUrl="/login" />
      )}
    </div>
  );
};
