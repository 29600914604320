import React from "react";

const FeatureCard = () => {
  return (
    <div className="relative invisible p-10 overflow-hidden border shadow-lg rounded-2xl shadow-zinc-100">
      <video
        playsInline=""
        src="https://videos.pexels.com/video-files/8333185/8333185-hd_1080_1080_30fps.mp4"
        className="absolute top-0 left-0 object-cover w-full h-full opacity-5"
      ></video>
      <div className="relative z-50">
        <h1 className="text-2xl font-semibold tracking-tighter">
          New features just shipped 🎉
        </h1>
        <p className="max-w-2xl mt-1 text-sm text-zinc-500 tex-balance">
          We've introduced a multitude of new features we think you'll love.
          Check them out!
        </p>
        <button
          type="button"
          aria-haspopup="dialog"
          aria-expanded="false"
          aria-controls="radix-:rh:"
          data-state="closed"
          className="inline-flex items-center px-3 py-2 mt-4 text-sm font-medium text-white transition duration-150 bg-blue-600 rounded-lg hover:bg-blue-700"
        >
          Read more{" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
            data-slot="icon"
            className="w-3 h-3 ml-1"
          >
            <path
              fillRule="evenodd"
              d="M5.22 14.78a.75.75 0 0 0 1.06 0l7.22-7.22v5.69a.75.75 0 0 0 1.5 0v-7.5a.75.75 0 0 0-.75-.75h-7.5a.75.75 0 0 0 0 1.5h5.69l-7.22 7.22a.75.75 0 0 0 0 1.06Z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
      </div>
    </div>
  );
};

export default FeatureCard;
