import React from "react";
import { Popconfirm } from "antd";
import { useDispatch } from "react-redux";
import { useFormEditorContext } from "../../../Contexts/FormEditorContext";
import { removeForm } from "../../../Redux/slices/formSlice.tsx";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../Constants/routes.js";
import { useMutation } from "@tanstack/react-query";
import { message } from "antd";
import { useApi } from "../../../Api/useApi.ts";
import { useQueryClient } from "@tanstack/react-query";

export const FormDeleteButton = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { form } = useFormEditorContext();

  const [messageApi] = message.useMessage();
  const api = useApi();
  const mutation = useMutation({
    mutationFn: () => api.forms.deleteForm(form?.url_id),
    onSuccess: () => {
      dispatch(removeForm({ id: form.id }));
      queryClient.invalidateQueries({ queryKey: ["formList"] });
      navigate(ROUTES.DASHBOARD);
    },
    onError: (error) => {
      messageApi.error("Could not delete form");
      alert(error);
    },
  });

  const deleteForm = () => {
    mutation.mutate(form?.url_id);
  };

  return (
    <Popconfirm
      title="Delete the form"
      description="Are you sure to delete this form?"
      onConfirm={deleteForm}
      okText="Delete"
      cancelText="Cancel"
    >
      <button className="flex items-center justify-center w-full p-2 text-xs font-medium rounded-lg hover:bg-rose-100 text-rose-500">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
          data-slot="icon"
          className="w-3 h-3 mr-1"
        >
          <path
            fill-rule="evenodd"
            d="M8.75 1A2.75 2.75 0 0 0 6 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 1 0 .23 1.482l.149-.022.841 10.518A2.75 2.75 0 0 0 7.596 19h4.807a2.75 2.75 0 0 0 2.742-2.53l.841-10.52.149.023a.75.75 0 0 0 .23-1.482A41.03 41.03 0 0 0 14 4.193V3.75A2.75 2.75 0 0 0 11.25 1h-2.5ZM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4ZM8.58 7.72a.75.75 0 0 0-1.5.06l.3 7.5a.75.75 0 1 0 1.5-.06l-.3-7.5Zm4.34.06a.75.75 0 1 0-1.5-.06l-.3 7.5a.75.75 0 1 0 1.5.06l.3-7.5Z"
            clip-rule="evenodd"
          ></path>
        </svg>
        Delete
      </button>
    </Popconfirm>
  );
};
