import { motion, AnimatePresence } from "framer-motion";
import React from "react";
import { Question } from "../Components/Form/Question.jsx";
import { QuestionView } from "./QuestionView.tsx";
import {
  QuestionProvider,
  useQuestionContext,
} from "../Contexts/QuestionContext";
import { QuestionBackground } from "../Components/Form/QuestionBackground.tsx";
import { Col, ConfigProvider, Row, Grid } from "antd";
import Center from "../Components/Basic/Center.jsx";
import { WelcomeScreen } from "../Components/Form/WelcomeScreen.jsx";
import { FormHeader } from "../Components/Form/FormHeader.jsx";
import { FormFooter } from "../Components/Form/FormFooter.jsx";
// import { useEnterPress } from "../Hooks/handleKeyPress.js";
import {
  elementAnimation,
  fadeAnimation,
  questionAnimation,
} from "../Constants/animations.js";
import tinycolor from "tinycolor2";
import {
  answerCheckboxInputTextSize,
  answerInputTextSize,
  buttonTextSize,
  selectOptionSize,
} from "../Constants/styles.js";
import { LoadingScreen } from "../Components/Form/LoadingScreen.jsx";
import { useFormContext } from "../Contexts/FormContext.jsx";
import KeyboardEventHandler from "react-keyboard-event-handler";
import { EndScreen } from "../Components/Form/EndScreen.jsx";
import { NEXT_QUESTION_KEYS } from "../Constants/keys.js";
import { AttachmentLayout } from "../Components/Form/AttachmentLayout.jsx";
import { SwipeDetector } from "../Components/Basic/SwipeDetector.jsx";
import { useFormEditorContext } from "../Contexts/FormEditorContext.jsx";

export const FormView = ({ editor = false }) => {
  const {
    isLoading,
    updateQuestion,
    currentQuestionIndex,
    percentage,
    isInitialPage,
    next,
    styles,
    questions,
    isEndPage,
    nextButtonPress,
    previous,
    showNextButton,
    formTheme,
  } = useFormContext();
  const isFormEditor = location.pathname.includes("form-editor");
  const { theme } = useFormEditorContext();
  const constTheme = isFormEditor ? theme : formTheme;

  const dynamicWidth = isFormEditor ? "75vw" : "100vw";
  // console.log({ currentQuestionIndex, isInitialPage });

  // useEnterPress(next);

  const primary_color = styles.primary_color;
  const secondary_color = styles.secondary_color;
  const buttonTextColor = "#000";
  const textColor = secondary_color;

  /*   const fontFam = constTheme?.font_family
    ? constTheme?.font_family
    : "Lexend Deca"; */
  const fontFam = constTheme?.font_family
    ? constTheme?.font_family
    : "Lexend Deca";

  const nextPressed = () => {
    if (showNextButton) {
      next();
    } else {
      console.log("next button is false ");
    }
  };
  console.log("showNextButton", showNextButton);
  const noQuestions = questions?.length === 0;
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: primary_color,
          fontFamily: constTheme?.font_family,
          colorTextQuaternary: tinycolor(secondary_color)
            .lighten(25)
            .toString(),
          borderRadius: 4,
        },
        // Typography : {

        // },
        components: {
          Button: {
            contentFontSizeLG: buttonTextSize,
            fontWeight: "bold",
            primaryColor: constTheme?.button_text_color,
            defaultActiveColor: buttonTextColor,
            defaultBg: constTheme?.button_color,
            defaultHoverColor: constTheme?.button_text_color,
            defaultHoverBg: tinycolor(constTheme?.button_color)
              .lighten(10)
              .toString(),
            defaultHoverBorderColor: tinycolor(constTheme?.button_color)
              .lighten(10)
              .toString(),
            defaultColor: constTheme?.button_text_color,
          },
          Select: {
            optionFontSize: selectOptionSize,
            optionSelectedBg: "transparent",
            paddingContentHorizontalLG: 0,
            showArrowPaddingInlineEnd: 0,
          },
          Input: {
            inputFontSizeSM: answerCheckboxInputTextSize,
            inputFontSizeLG: answerInputTextSize,
            colorTextPlaceholder: tinycolor(secondary_color)
              ?.setAlpha(0.2)
              .toString(),
            paddingInlineSM: 0,
            paddingInlineLG: 0,
            colorText: textColor,
            fontWeightStrong: 800,
            colorBorder: textColor,
          },
          Checkbox: {
            colorBorder: secondary_color,
            colorPrimary: secondary_color,
            colorBgTextActive: secondary_color,
          },
        },
      }}
    >
      <SwipeDetector onScrollUp={nextButtonPress} onScrollDown={previous}>
        <KeyboardEventHandler
          handleKeys={NEXT_QUESTION_KEYS}
          onKeyEvent={(key, e) => {
            console.log("FormView enter/tab key pressed");
            nextPressed();
            // nextButtonPress()
          }}
        />
        <QuestionBackground>
          {isLoading ? (
            <motion.div key="loading-screen" {...fadeAnimation}>
              <LoadingScreen />
            </motion.div>
          ) : isInitialPage ? (
            <motion.div {...elementAnimation} key="welcome-screen">
              <WelcomeScreen editor={editor} />
            </motion.div>
          ) : isEndPage || noQuestions ? (
            <motion.div {...elementAnimation} key={"end-screen"}>
              <FormHeader />
              <EndScreen />
            </motion.div>
          ) : (
            <motion.div
              key={"form-123213233243214"}
              {...questionAnimation}
              style={{
                minHeight: "100vh",
                maxHeight: "100vh",
                height: "100%",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexDirection: "column",
              }}
            >
              <FormHeader />
              <AnimatePresence
                key={"fdjsonf"}
                mode="wait"
                // onExitComplete={updateQuestion}
              >
                <motion.div
                  key={questions[currentQuestionIndex]?.id}
                  {...questionAnimation}
                >
                  <QuestionProvider question={questions[currentQuestionIndex]}>
                    <QuestionPageLayout dynamicWidth={dynamicWidth} />
                  </QuestionProvider>
                </motion.div>
              </AnimatePresence>

              {editor ? (
                <div className="flex justify-end w-full mb-4 ">
                  <FormFooter />
                </div>
              ) : (
                <FormFooter />
              )}
            </motion.div>
          )}
        </QuestionBackground>
      </SwipeDetector>
    </ConfigProvider>
  );
};

export const QuestionPageLayout = ({ dynamicWidth }) => {
  const { currentQuestion } = useQuestionContext();
  const { useBreakpoint } = Grid;
  const { xs, sm, md, lg, xl } = useBreakpoint();

  //const hasAttachment = !!currentQuestion?.attachment?.link;
  const hasAttachment = currentQuestion?.attachment?.link;
  //console.log("hasAttahement", hasAttachment);

  const attachmentPlacement =
    (hasAttachment && currentQuestion?.attachment?.placement) || "right";

  // console.log({ hasAttachment, attachmentPlacement });

  return (
    <>
      <div className="block md:hidden">
        <div className="flex flex-col gap-2">
          {!hasAttachment && <QuestionView />}

          {hasAttachment && attachmentPlacement == "left" && (
            <>
              <AttachmentLayout attachment={currentQuestion.attachment} />
              <QuestionView />
            </>
          )}

          {hasAttachment && attachmentPlacement == "right" && (
            <>
              <QuestionView />
              <AttachmentLayout attachment={currentQuestion.attachment} />
            </>
          )}
        </div>
      </div>

      <div className="hidden md:flex">
        <Row style={{ width: dynamicWidth }}>
          {hasAttachment && attachmentPlacement == "left" && (
            <Col
              style={{
                display: "flex",
                flex: 1,
                alignItems: "center",
                justifyContent: "center",
              }}
              lg={24}
              md={24}
              sm={24}
            >
              <AttachmentLayout attachment={currentQuestion.attachment} />
            </Col>
          )}
          <Col
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              flex: 1,
            }}
            lg={24}
            md={24}
            sm={24}
          >
            <QuestionView />
          </Col>
          {hasAttachment && attachmentPlacement == "right" && (
            <Col
              style={{
                display: "flex",
                flex: 1,
                alignItems: "center",
                justifyContent: "center",
                maxWidth: "70%",
              }}
              lg={24}
              md={24}
              sm={24}
            >
              <AttachmentLayout attachment={currentQuestion.attachment} />
            </Col>
          )}
        </Row>
      </div>
    </>
  );
};
