import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
const pageVariants = {
  initial: {
    opacity: 0,
  },
  in: {
    opacity: 1,
  },
  out: {
    opacity: 0,
  },
};

const pageTransition = {
  type: "tween",
  ease: "linear",
  duration: 0.5,
};

const AnimationLayout = ({ children }) => {
  const { pathname } = useLocation();
  return (
    <motion.div
      key={pathname}
      initial="initial"
      animate="in"
      variants={pageVariants}
      transition={pageTransition}
      style={{
        // backgroundColor: "red",
        height: "auto",
        flex: 1,
        alignContent: "center",
        // alignSelf: 'center'
        // alignItems: "center",
        // justifyContent: "center",
        // width: "100vh"
      }}
    >
      {children}
      <Outlet />
    </motion.div>
  );
};

export default AnimationLayout;
