import { useEffect, useState } from "react";
import axios from "axios";
import { getCountries } from "react-phone-number-input";


export const useCurrentCountry = () => {
  const [countryCode, setCountryCode] = useState(null);
  const countries = getCountries();
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Replace `YOUR_API_ENDPOINT` with the actual endpoint
        const response = await axios
          .get("https://api.hostip.info/country.php")
          .catch((e) => {
            // console.log(e);
          });
        // console.log({ response, country: response.data });
        if (response.data && countries.includes(response.data)){
            setCountryCode("LK")
            // setCountryCode(response.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return { countryCode };
};
