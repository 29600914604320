import { IForm, ITheme } from "../Redux/slices/formSlice";
import { randomString } from "../Utils/string";

export const newFormProps = (
  formName: string,
  selectedWorkSpace: string,
  theme: any
): IForm => {
  return {
    id: randomString(10),
    name: formName || `Form ${randomString(10)}`,
    workspace: {
      _id: selectedWorkSpace,
    },
    type: "quiz",
    owner_id: "",
    url_id: "",
    created_at: "2024-07-22T11:03:27.619+00:00",
    last_updated_at: "2024-07-22T11:03:27.619+00:00",
    theme: {
      primary_color: theme.primary_color,
      secondary_color: theme.secondary_color,
      text_color: theme.text_color,
      text_size: theme.text_size,
      background_color: theme.background_color,
      button_color: theme.button_color,
      button_size: theme.button_size,
      button_text_color: theme.button_text_color,
      font_family: theme.font_family,
      font: theme.font,
      name: theme.name,
      visibility: theme.visibility,
      logo: {
        _id: theme.logo._id,
        type: theme.logo.type,
        link: theme.logo.link,
        properties: {
          description: theme.logo.properties,
          focal_point: {
            x: 0,
            y: 0,
          },
        },
      },
      rounded_corners: theme.rounded_corners,
      _id: theme?.id || theme?._id,
      created_at: theme.created_at,
      updated_at: theme.updated_at,
      owner_id: theme?.owner_id,
    },
    primary_color: "#1890ff",
    text_size: "medium",
    secondary_color: "#f0ff00",
    welcomeScreen: {
      title: "Welcome to our form",
      type: "url_redirect",
      description: "This is a description of the form",
      button_text: "Start",
      layout: {
        type: "float",
        attachment: {
          type: "image",
          href: "",
        },
        placement: "right",
      },
    },
    endScreen: {
      title: "Thank you",
      type: "url_redirect",
      description: "This is a description of the form end",
    },
    fields: [],
    settings: {
      language: "en",
      progress_bar: "percentage",
      meta: {
        title: "Bootcamp Application Form",
        allow_indexing: true,
      },
      hide_navigation: false,
      is_public: true,
      is_trial: false,
      show_progress_bar: true,
      show_typeform_branding: true,
      are_uploads_public: false,
      show_time_to_complete: false,
      show_number_of_submissions: true,
      show_cookie_consent: false,
      show_question_number: true,
      show_key_hint_on_choices: true,
      autosave_progress: true,
      free_form_navigation: false,
    },
    hidden: [],
    links: {
      display: "",
      responses: "",
    },
  };
};

export const newThemeProps = (): ITheme => {
  return {
    primary_color: "rgb(255, 87, 51)",
    secondary_color: "rgb(51, 255, 189)",
    text_color: "rgb(255, 255, 255)",
    background_color: "rgb(0, 0, 0)",
    font_family: "Arial, sans-serif",
    font: "16px",
    name: "default_theme",
    button_color: "rgb(255, 255, 255)",
    button_size: "large",
    button_text_color: "rgb(0, 0, 0)",
    text_size: "medium",
    visibility: "private",
    logo: {
      _id: "",
      type: "image",
      link: "",
      properties: {
        description: "Sample logo description",
        focal_point: {
          x: 0.5,
          y: 0.5,
        },
      },
    },
    rounded_corners: "large",
    _id: "",
    created_at: "2023-08-01T12:00:00Z",
    updated_at: "2023-08-12T12:00:00Z",
    owner_id: "",
  };
};
