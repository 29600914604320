import React, { useState, useEffect, useRef } from "react";
import { Input, Modal, List, Spin, message, TourProps, theme } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { UserButton } from "@clerk/clerk-react";
import { useQuery } from "@tanstack/react-query";
import { useApi } from "../Api/useApi.ts";
import { useDispatch, useSelector } from "react-redux";
import { setFormList, addNewForm } from "../Redux/slices/formSlice.tsx";
import { newFormProps, newThemeProps } from "../Constants/newForm.tsx";
import { useNavigate } from "react-router-dom";
import {
  QuestionCircleOutlined,
  PhoneOutlined,
  SettingOutlined,
  TagOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import FeatureCard from "../Components/DashboardComponents/FeatureCard.jsx";
import FormRepliesCard from "../Components/DashboardComponents/FormRepliesCard.jsx";
import FormCard from "../Components/DashboardComponents/FormCard.jsx";
import Workspaces from "../Components/DashboardComponents/Workspaces.jsx";
import { useFormEditorContext } from "../Contexts/FormEditorContext.jsx";
import { useUser } from "@clerk/clerk-react";
import { useMutation } from "@tanstack/react-query";
import {
  convertBackToOldFormat,
  convertFormObject,
} from "../Components/MyEditor/FormObjectConfig.jsx";
import { useQueryClient } from "@tanstack/react-query";
import CustomBilling from "../Components/DashboardComponents/CustomBilling.jsx";
import useKeyboardShortcut from "../Components/DashboardComponents/useKeyboardShortcut.jsx";
import CommonLayout from "./CommonLayout.jsx";

const { TextArea } = Input;

const DashboardLayout = () => {
  /* Data Fetchings  */
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { user } = useUser();
  const { selectedWorkSpace, setSelectedWorkSpace, setForm } =
    useFormEditorContext();
  const navigate = useNavigate();
  const [workSpaces, setWorkSpaces] = useState([]);
  const api = useApi();
  const { isFetching, data, isSuccess } = useQuery({
    queryKey: ["formList", selectedWorkSpace],
    queryFn: () => api.forms.fetchFormList(selectedWorkSpace),
    staleTime: Infinity,
    enabled: !!selectedWorkSpace,
  });

  useEffect(() => {
    if (isSuccess && data?.data?.forms) {
      dispatch(setFormList(data?.data?.forms));
    }
  }, [isSuccess, data, dispatch]);

  const formList = useSelector((state) => state.forms.forms);

  /* modal states */
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  useKeyboardShortcut(["cmd+k", "ctrl+k"], (key, e) => {
    e.preventDefault();
    console.log("Keyboard shortcut pressed", key);
    showModal();
  });
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const showCreateModal = () => {
    setIsCreateModalOpen(true);
  };
  const handleOkCreate = () => {
    setIsCreateModalOpen(false);
  };
  const handleCreateCancel = () => {
    setIsCreateModalOpen(false);
  };

  /* search  */
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState(workSpaces);
  const [inputKey, setInputKey] = useState(0); // Key to force re-render

  // Handle search functionality
  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);
    if (value.trim()) {
      const filtered = workSpaces.filter((item) =>
        item.name.toLowerCase().includes(value)
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(workSpaces);
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      setFilteredData(workSpaces);
      setInputKey((prevKey) => prevKey + 1);
    }
  }, [isModalOpen, workSpaces]);

  /* adding new form */

  const STATUSES = {
    INITIAL: "initial",
    LOADING: "loading",
    SUCCESS: "success",
    ERROR: "error",
  };
  const [formName, setFormName] = useState("");
  const [status, setStatus] = useState(STATUSES.INITIAL);
  const [spin, setSpin] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(true);

  const formCreateMutation = useMutation({
    mutationFn: (form) => api.forms.createForm(form),
    onSuccess: (data) => {
      console.log("form created");
      const newObj = convertBackToOldFormat(data?.data);
      dispatch(addNewForm(newObj));
      setTimeout(() => {
        setForm(newObj);
        navigate(`/form-editor/${newObj?.id}`);
        queryClient.invalidateQueries({ queryKey: ["formList"] });
        setIsCreateModalOpen(false);
        setSpin(false);
        setStatus(STATUSES.SUCCESS);
      }, 600);
    },
    onError: (error) => {
      if (error.response) {
        console.log(error.response);
      } else {
      }
      console.error("Error details:", error);
    },
  });

  const handleCreateNewForm = (theme) => {
    setStatus(STATUSES.LOADING);
    const sessionWorkspace = sessionStorage.getItem("selectedWorkSpace");
    if (!sessionWorkspace || sessionWorkspace === "undefined") {
      message.warning("Please create a workspace first.");
      setStatus(STATUSES.IDLE);
      setIsCreateModalOpen(false);
      setIsCollapsed(false);
      return;
    }
    setSpin(true);
    setTimeout(() => {
      const newForm = newFormProps(formName, selectedWorkSpace, theme);
      const newFormat = convertFormObject(newForm);
      formCreateMutation.mutate(newFormat);
    }, 600);
  };

  const createMutation = useMutation({
    mutationFn: (newTheme) => api.themes.createTheme(newTheme),
    onSuccess: (data) => {
      /* need to create the form then  */
      const theme = data?.data;
      handleCreateNewForm(theme);
    },
    onError: (error) => {
      console.error("Error details:", error);
    },
  });

  const themeCreate = () => {
    const newTheme = newThemeProps();
    createMutation.mutate(newTheme);
  };

  const dashboardSidebar = (
    <div className="w-[20rem] h-full bg-white px-5 pt-4 flex flex-col relative">
      {/* user nav sec */}

      <UserButton showName="true" afterSignOutUrl="/login">
        <UserButton.UserProfilePage
          label="Billing"
          url="custom"
          labelIcon={<TagOutlined />}
        >
          <CustomBilling />
        </UserButton.UserProfilePage>
      </UserButton>

      {/* Search sec */}

      <div onClick={showModal}>
        <Input
          placeholder="Search"
          variant="filled"
          prefix={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="#959595"
              aria-hidden="true"
              data-slot="icon"
              className="h-3.5 w-3.5"
            >
              <path
                fillRule="evenodd"
                d="M8.5 2.75a5.75 5.75 0 1 0 0 11.5 5.75 5.75 0 0 0 0-11.5Zm0 1.5a4.25 4.25 0 1 1 0 8.5 4.25 4.25 0 0 1 0-8.5Zm6.72 11.28a.75.75 0 0 1-1.06 1.06l-3.25-3.25a.75.75 0 0 1 1.06-1.06l3.25 3.25Z"
              ></path>
            </svg>
          }
          suffix={<span className=" text-[#959595]">⌘ K</span>}
          className="rounded-lg mx-0 mt-5 bg-[#F4F4F5]"
        />
      </div>

      {/* add workspace sec */}
      <Workspaces
        workSpaces={workSpaces}
        setWorkSpaces={setWorkSpaces}
        selectedWorkSpace={selectedWorkSpace}
        setSelectedWorkSpace={setSelectedWorkSpace}
        isCollapsed={isCollapsed}
        setIsCollapsed={setIsCollapsed}
      />

      {/* Helps */}

      <div className="flex flex-col items-start justify-center pb-4 mt-auto">
        <p className="mb-2 text-xs font-medium opacity-30">Helps</p>
        <div className="flex flex-col gap-4 text-xs font-semibold ">
          <button className="flex items-center gap-2 hover:font-bold text-start">
            <QuestionCircleOutlined /> Help Docs
          </button>
          <a
            className="flex items-center gap-2 hover:font-bold text-start"
            href="https://calendly.com/ronath-buildform/30min?month=2024-09"
            target="_blank"
          >
            <PhoneOutlined />
            Talk to us{" "}
          </a>
          <a
            className="flex items-center gap-2 hover:font-bold text-start"
            href="https://light-dinghy-643.notion.site/Change-Log-613d1bb815fa4a3a8389467b9644cb43?pvs=4"
            target="_blank"
          >
            <SettingOutlined />
            Change logs
          </a>
        </div>
      </div>

      {/* unlimited Replies sec */}
      <div className="pt-2 pb-4 border-t border-zinc-200">
        <div className="text-xs transition duration-150 text-zinc-500">
          <p className="text-zinc-950 text-[0.65rem] font-medium uppercase tracking-widest">
            UNLIMITED REPLIES
          </p>
          <div className="w-full h-1.5 rounded-full bg-zinc-100 my-3 overflow-hidden">
            <div className="w-full h-full bg-blue-600 rounded-full"></div>
          </div>
          <div className="text-left ">
            If you require specific features, such as brand removal, analytics,
            integrations or anything else, you can fill out this quick{" "}
            <a
              target="_blank"
              className="inline-flex items-center font-medium text-blue-600 underline transition duration-150 hover:opacity-60"
              href=""
            >
              Form
            </a>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <CommonLayout sidebar={dashboardSidebar}>
        {/* main section */}

        <div className="relative w-full h-full px-8 overflow-y-auto transition duration-200 border shadow-xl rounded-2xl border-zinc-200">
          <section className="flex flex-col justify-between gap-4 pt-3 lg:items-center lg:flex-row">
            <div className="flex flex-col gap-4 mt-3.5">
              <h1 className="flex flex-col items-center mb-1 text-xl ">
                <p className="text-[2rem] font-bold text-start">
                  Hey {user?.firstName} 👋
                </p>
              </h1>
              <p className="hidden text-sm text-zinc-500">
                Find all the forms you have created
              </p>
              <p className="text-lg font-medium text-start">My Forms</p>
            </div>
            <button
              type="primary"
              className="flex items-center justify-center px-2 py-2 font-medium text-white bg-black rounded-lg hover:bg-zinc-800"
              onClick={showCreateModal}
            >
              <PlusOutlined className=" w-3.5 h-3.5 text-base"> </PlusOutlined>
              <span className="ml-1 text-sm">New Form</span>
            </button>
          </section>
          <div className="grid gap-4 my-6 lg:grid-cols-3">
            {/* Replies card */}
            <FormRepliesCard />
            {/* Feature Card */}
            <FeatureCard />
          </div>
          <p className="mt-6 mb-3 text-sm text-start text-zinc-500">
            Recent forms
          </p>
          <section className="grid grid-cols-1 gap-4 pb-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5">
            <button
              type="button"
              aria-haspopup="dialog"
              aria-expanded="false"
              aria-controls="radix-:rk:"
              data-state="closed"
              onClick={showCreateModal}
              className="h-[12rem] relative border border-zinc-200 border-dashed rounded-2xl hover:scale-[.98] transition duration-500 flex items-center gap-1 flex-col justify-center hover:bg-zinc-100"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
                data-slot="icon"
                className="w-6 h-6"
              >
                <path d="M10.75 4.75a.75.75 0 0 0-1.5 0v4.5h-4.5a.75.75 0 0 0 0 1.5h4.5v4.5a.75.75 0 0 0 1.5 0v-4.5h4.5a.75.75 0 0 0 0-1.5h-4.5v-4.5Z"></path>
              </svg>
              <p className="text-sm text-zinc-500">Create a form</p>
            </button>
            <FormCard formList={formList} isFetching={isFetching} />
          </section>
        </div>
      </CommonLayout>
      {/* Search Modal */}
      <Modal
        title=""
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
        closeIcon={null}
        transitionName={""}
        afterOpen={() => setInputKey((prevKey) => prevKey + 1)}
      >
        <div
          style={{
            maxHeight: "160px",
            overflow: "auto",
            scrollbarColor: "dark",
          }}
        >
          <Input
            key={inputKey}
            placeholder="Search"
            value={searchTerm}
            onChange={handleSearch}
            variant="filled"
            autoFocus
          />
          <List
            dataSource={filteredData} // Display filtered data only
            renderItem={(item) => (
              <List.Item
                key={item._id}
                onClick={() => {
                  setSelectedWorkSpace(item?._id);
                  handleCancel();
                }}
                className="font-medium hover:font-semibold"
              >
                {item?.name}
              </List.Item>
            )}
          />
        </div>
      </Modal>

      {/* Create Form modal */}
      <Modal
        title={
          <div className="flex flex-col ">
            <h1 className="flex items-center mb-1 text-xl font-medium tracking-tight">
              Create New Form
            </h1>
            {/* <p className="text-sm font-normal text-zinc-500">
                Tell our AI What you need in the new form
              </p> */}
          </div>
        }
        open={isCreateModalOpen}
        onOk={handleOkCreate}
        onCancel={handleCreateCancel}
        footer={[]}
        closeIcon={null}
        width={400}
        transitionName={""}
      >
        {!spin ? (
          <main className="flex flex-col gap-4 ">
            <span className="mt-4 text-sm text-zinc-500">Form Name</span>
            <Input
              placeholder="Form Name"
              className="mx-0 mb-4 rounded-lg shadow-md"
              onChange={(e) => setFormName(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  themeCreate();
                }
              }}
            />
            {/*    <span className="mt-4 text-sm text-zinc-500">Description</span>
 <TextArea
   placeholder="Form Description"
   className="mx-0 mb-4 rounded-lg shadow-md"
   onChange={(e) => setFormDescription(e.target.value)}
 /> */}
            <div className="flex justify-end gap-2 mt-auto ">
              <button
                className="  flex items-center bg-white  hover:bg-[#F4F4F5] rounded-lg py-2 px-2 text-black font-medium"
                onClick={handleCreateCancel}
              >
                Cancel
              </button>
              <button
                className="flex items-center gap-2 px-2 py-2 font-medium text-white bg-black rounded-lg hover:bg-zinc-800"
                onClick={() => {
                  themeCreate();
                }}
              >
                Create Form
              </button>
            </div>
          </main>
        ) : (
          <div className="flex items-center min-h-[154px] justify-center gap-2 ">
            <Spin
              indicator={<LoadingOutlined spin />}
              spinning={spin}
              className="text-black "
            />
            <p className="font-medium ">Creating your form</p>
          </div>
        )}
      </Modal>
    </>
  );
};

export default DashboardLayout;
