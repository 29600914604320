import { Alert, Button, Flex, Typography } from "antd";
import { useQuestionContext } from "../../Contexts/QuestionContext";
import { AnimatePresence, motion } from "framer-motion";
import { CheckOutlined, EnterOutlined } from "@ant-design/icons";
import { formButtonProps } from "../../Constants/styles";
import { FormNextButton } from "./FormNextButton";
import { useFormContext } from "../../Contexts/FormContext";

export const FormButton = ({ children, onClick, ...rest }) => {
  const { formInstance, currentQuestionIndex } = useFormContext();
  const { currentQuestion } = useQuestionContext();
  const { error } = useQuestionContext();

  //   console.log("formInstance error ", error);

  const errors = Object.values(error).filter((a) => a);
  const fields = Object.keys(error).filter((a) => a);
  //   console.log("errors", errors);
  //   console.log("fields", fields);

  //   console.log({ error });

  return (
    <div style={{ paddingTop: "20px" }}>
      <AnimatePresence mode="wait">
        {errors.length > 0 ? (
          fields.length > 1 ? (
            <motion.div
              key={`errors ${currentQuestionIndex}`}
              {...formButtonProps}
            >
              <Alert
                message="Oops! check for errors in the form"
                type="error"
                showIcon
              />
            </motion.div>
          ) : (
            <motion.div key={`${error[0]}mfkdsf`} {...formButtonProps}>
              <Alert message={errors[0]} type="error" showIcon />
            </motion.div>
          )
        ) : (
          <FormNextButton
            // onClick={onClick}
            key={"next" + currentQuestionIndex}
            currentQuestionIndex={currentQuestionIndex}
            {...rest}
          >
            {currentQuestion?.properties?.button_text
              ? currentQuestion.properties.button_text
              : currentQuestion?.type === "section"
              ? "Continue"
              : "Next"}
          </FormNextButton>
        )}
      </AnimatePresence>
    </div>
  );
};
