import { Switch } from "antd";
import React, { useState, useEffect, useRef } from "react";
import { PlusOutlined, DeleteFilled } from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";
import { QUESTION_TYPES } from "../../../Constants/questionTypes";
import { UploadOutlined } from "@ant-design/icons";
import { useMutation } from "react-query";
import { useApi } from "../../../Api/useApi.ts";

export const FieldSettingEditor = ({
  label,
  type,
  editorField,
  setEditorField,
}) => {
  const formatLabel = (label) => {
    return label.replace(/_/g, " ");
  };

  const getValueFromNestedKey = (obj, key) => {
    return key.split(".").reduce((o, i) => (o ? o[i] : ""), obj);
  };

  const setValueToNestedKey = (obj, key, value) => {
    const keys = key.split(".");
    const lastKey = keys.pop();
    const deepClone = (obj) => {
      if (obj === null || typeof obj !== "object") return obj;
      if (Array.isArray(obj)) return obj.map(deepClone);
      const clonedObj = {};
      for (let k in obj) {
        clonedObj[k] = deepClone(obj[k]);
      }
      return clonedObj;
    };
    const newObj = deepClone(obj);
    const deep = keys.reduce((o, i) => {
      if (!o[i]) o[i] = {};
      return o[i];
    }, newObj);
    deep[lastKey] = value;
    return newObj;
  };

  return (
    <div
      className={`flex gap-2 my-1 flex-wrap text-sm ${
        type === "Switch" || type === "Image" || type === "Placement"
          ? "justify-between items-center"
          : "flex-col items-start"
      }`}
    >
      <p className={`text-sm font-medium`}>
        {formatLabel(label.split(".").pop())}
      </p>
      <FieldRenderer
        type={type}
        label={label}
        setEditorField={setEditorField}
        editorField={editorField}
        getValueFromNestedKey={getValueFromNestedKey}
        setValueToNestedKey={setValueToNestedKey}
      />
    </div>
  );
};

const FieldRenderer = ({
  type,
  label,
  editorField,
  setEditorField,
  getValueFromNestedKey,
  setValueToNestedKey,
}) => {
  const handleSwitchChange = (checked) => {
    setEditorField((prev) => {
      const newEditorField = setValueToNestedKey(prev, label, checked);
      return newEditorField;
    });
  };

  const handleInputChange = (e) => {
    setEditorField((prev) => {
      const newEditorField = setValueToNestedKey(prev, label, e.target.value);
      return newEditorField;
    });
  };

  const [localArray, setLocalArray] = useState([]);

  useEffect(() => {
    if (type === "ArrayInput") {
      const arrayValue = getValueFromNestedKey(editorField, label);
      if (Array.isArray(arrayValue)) {
        setLocalArray(arrayValue.map((item) => ({ ...item })));
      } else {
        setLocalArray([]);
      }
    }
  }, [type, editorField, label, getValueFromNestedKey]);

  const handleArrayChange = (index, value) => {
    const newArray = [...localArray];
    newArray[index].label = value;
    setLocalArray(newArray);
    updateEditorField(newArray);
  };

  const handleAddField = () => {
    const newArray = [...localArray, { id: uuidv4(), label: "" }];
    setLocalArray(newArray);
    updateEditorField(newArray);
  };

  const handleRemoveField = (index) => {
    const newArray = localArray.filter((_, i) => i !== index);
    setLocalArray(newArray);
    updateEditorField(newArray);
  };

  const updateEditorField = (newArray) => {
    setEditorField((prev) => {
      const newEditorField = setValueToNestedKey(prev, label, newArray);
      return newEditorField;
    });
  };

  const handleBlur = () => {
    setEditorField((prev) => {
      const newEditorField = setValueToNestedKey(prev, label, [...localArray]);
      return newEditorField;
    });
  };

  /* handle contact_info object */
  const [arrayObj, setArrayObj] = useState([]);

  useEffect(() => {
    if (type === "ArrayObject") {
      const arrayValue = getValueFromNestedKey(editorField, label);
      if (Array.isArray(arrayValue)) {
        setArrayObj(arrayValue.map((item) => ({ ...item })));
      } else {
        setArrayObj([]);
      }
    }
  }, [type, editorField, label, getValueFromNestedKey]);

  const addContactFields = () => {
    const newArray = [
      ...arrayObj,
      {
        id: uuidv4(),
        title: "",
        type: QUESTION_TYPES.SHORT_TEXT,
        properties: {
          description: "",
        },
        validations: {
          required: "true",
        },
      },
    ];
    setLocalArray(newArray);
    updateEditorField(newArray);
  };

  const removeContactFields = (index) => {
    const newArray = arrayObj.filter((_, i) => i !== index);
    setArrayObj(newArray);
    updateEditorField(newArray);
  };

  const updateContactFields = (index, key, value) => {
    const newArray = [...arrayObj];
    newArray[index][key] = value;
    setArrayObj(newArray);
    updateEditorField(newArray);
  };

  /* attachment handling  */
  //const [image, setImage] = useState(null);
  const [preview, setPreview] = useState(editorField?.attachment?.link || null);
  const fileInputRef = useRef(null);

  const [currentPlacement, setCurrentPlacement] = useState("right");
  //console.log("placement placement : ", currentPlacement);

  const handlePlacement = (currentPlacement) => {
    setCurrentPlacement(currentPlacement);

    setEditorField((prev) => {
      const currentField = prev || {};

      const newEditorField = {
        ...prev,
        attachment: {
          ...currentField.attachment,
          placement: currentPlacement,
        },
      };

      return newEditorField;
    });
  };

  const updateAttachment = (key, value) => {
    setEditorField((prev) => {
      const currentAttachment = prev.attachment || {};

      const updatedField = {
        ...prev,
        attachment: {
          ...currentAttachment,
          [key]: value,
        },
      };

      return updatedField;
    });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const previewUrl = reader.result;
        setPreview(previewUrl);
        updateAttachment("link", previewUrl);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveImage = () => {
    setPreview(null);
    updateAttachment("link", null); // Reset the link in the editorField

    // Reset the file input
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  switch (type) {
    case "Switch":
      return (
        <Switch
          key={label}
          checked={getValueFromNestedKey(editorField, label) || false}
          size="small"
          onChange={handleSwitchChange}
        />
      );
    case "ShortText":
      return (
        <input
          key={label}
          className="w-full p-2 border border-gray-300 rounded-md focus:border-black focus:outline-none"
          value={getValueFromNestedKey(editorField, label) || ""}
          onChange={handleInputChange}
        />
      );
    case "ArrayInput":
      return (
        <div className="flex flex-col w-full gap-2">
          {localArray.map((item, index) => (
            <div key={item.id} className="flex gap-2 my-1">
              <input
                className="w-full px-[6px] py-[1px] border border-gray-300 rounded-md focus:border-black focus:outline-none"
                value={item.label}
                onChange={(e) => handleArrayChange(index, e.target.value)}
                onBlur={handleBlur}
              />
              <button
                className="p-2 transition-all duration-200 ease-in-out bg-white border border-gray-300 rounded-lg ring-black border-1 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50 animate-scale-up-sm"
                onClick={() => handleRemoveField(index)}
              >
                <DeleteFilled />
              </button>
            </div>
          ))}
          <button
            className="items-center w-[30px] h-[32px] transition-all duration-200 ease-in-out bg-black border rounded-lg border-zinc-900 ring-black border-1 hover:bg-zinc-800 focus:outline-none focus:ring-2 focus:ring-zinc-700 focus:ring-opacity-50 animate-scale-up-sm"
            onClick={handleAddField}
          >
            <PlusOutlined className="font-bold text-white " />
          </button>
        </div>
      );
    case "LongText":
      return (
        <textarea
          key={label}
          className="w-full p-2 border border-gray-300 rounded-md focus:border-black focus:outline-none"
          value={getValueFromNestedKey(editorField, label) || ""}
          onChange={handleInputChange}
        />
      );

    case "ArrayObject":
      return (
        <div className="flex flex-col w-full gap-2">
          {arrayObj &&
            arrayObj?.map((item, index) => (
              <div
                key={item.id}
                className="flex flex-col gap-2 p-2 my-1 border"
              >
                <input
                  value={item.title}
                  onChange={(e) =>
                    updateContactFields(index, "title", e.target.value)
                  }
                  className="w-full p-2 border border-gray-300 rounded-md focus:border-black focus:outline-none"
                />

                <button
                  className="p-2 transition-all duration-200 ease-in-out bg-white border border-gray-300 rounded-lg ring-black border-1 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50 animate-scale-up-sm"
                  onClick={() => removeContactFields(index)}
                >
                  <DeleteFilled />
                </button>
              </div>
            ))}
          <button
            className="items-center w-[30px] h-[32px] transition-all duration-200 ease-in-out bg-black border rounded-lg border-zinc-900 ring-black border-1 hover:bg-zinc-800 focus:outline-none focus:ring-2 focus:ring-zinc-700 focus:ring-opacity-50 animate-scale-up-sm"
            onClick={addContactFields}
          >
            <PlusOutlined className="font-bold text-white " />
          </button>
        </div>
      );

    case "Image":
      return (
        <>
          <button
            className="flex items-center gap-2 p-1 font-medium border border-gray-300 rounded-md focus:border-black focus:outline-none hover:border-black"
            onClick={() => fileInputRef.current.click()}
          >
            <UploadOutlined />
            Upload
          </button>
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            ref={fileInputRef}
            className="hidden"
          />
          {preview && (
            <div className="w-full mt-4">
              <img
                src={preview}
                alt="Image Preview"
                className="h-auto max-w-full border border-gray-300 rounded-lg"
              />
              <button
                className="p-1 mt-2 font-medium border border-gray-300 rounded-md focus:border-black focus:outline-none hover:border-black"
                onClick={handleRemoveImage} // Optional: To remove the image preview
              >
                Remove Image
              </button>
            </div>
          )}
        </>
      );

    case "Placement":
      return (
        <div className="flex items-center gap-2">
          <button
            className="p-1 border border-gray-300 rounded-md focus:border-black focus:outline-none hover:border-black"
            onClick={() => handlePlacement("right")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="20"
              viewBox="0 0 28 20"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14 1C14 0.447715 14.4477 0 15 0H26C27.1046 0 28 0.895431 28 2V18C28 19.1046 27.1046 20 26 20H15C14.4477 20 14 19.5523 14 19V1ZM3 11.75C3 11.3358 3.33579 11 3.75 11H8.25C8.66421 11 9 11.3358 9 11.75C9 12.1642 8.66421 12.5 8.25 12.5H3.75C3.33579 12.5 3 12.1642 3 11.75ZM3.75 8C3.33579 8 3 8.33579 3 8.75C3 9.16421 3.33579 9.5 3.75 9.5H10.25C10.6642 9.5 11 9.16421 11 8.75C11 8.33579 10.6642 8 10.25 8H3.75Z"
                fill="currentColor"
              ></path>
            </svg>
          </button>

          <button
            className="p-1 border border-gray-300 rounded-md focus:border-black focus:outline-none hover:border-black"
            onClick={() => handlePlacement("left")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="20"
              viewBox="0 0 28 20"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 2C0 0.895431 0.895431 0 2 0H13C13.5523 0 14 0.447716 14 1V19C14 19.5523 13.5523 20 13 20H2C0.895431 20 0 19.1046 0 18V2ZM17 11.75C17 11.3358 17.3358 11 17.75 11H22.25C22.6642 11 23 11.3358 23 11.75C23 12.1642 22.6642 12.5 22.25 12.5H17.75C17.3358 12.5 17 12.1642 17 11.75ZM17.75 8C17.3358 8 17 8.33579 17 8.75C17 9.16421 17.3358 9.5 17.75 9.5H24.25C24.6642 9.5 25 9.16421 25 8.75C25 8.33579 24.6642 8 24.25 8H17.75Z"
                fill="currentColor"
              ></path>
            </svg>
          </button>
        </div>
      );

    default:
      return <div></div>;
  }
};

export default FieldRenderer;
