import React, { useRef, useImperativeHandle, useState, useEffect } from "react";
import "react-phone-number-input/style.css";
import PhoneInput, { getCountries } from "react-phone-number-input";
import countryNames from "react-phone-number-input/locale/en";

import { ConfigProvider, Input } from "antd";
import { useFormContext } from "../../../Contexts/FormContext";
import { getInputStyle } from "../../../Constants/styles";
import { CountrySelect } from "./CountrySelect";
import { useCookies } from "react-cookie";
import { getCountryCallingCode } from "../../../Utils/countries";
import KeyboardEventHandler from "react-keyboard-event-handler";
import { NEXT_QUESTION_KEYS } from "../../../Constants/keys";
import tinycolor from "tinycolor2";

const MyInput = React.forwardRef((props, ref) => {
  const inputRef = useRef(null);

  useImperativeHandle(
    ref,
    () => {
      return inputRef.current.input;
    },
    []
  );
  return (
    <>
      <Input {...props} ref={inputRef} variant="borderless" />
    </>
  );
});

export const PhoneNumberInput = ({ value, onChange, ...props }) => {
  const { currentQuestion } = useFormContext();
  const [cookies, setCookie] = useCookies(["phone_country"]);
  //   const [country, _setCountry] = useState(null);
  const { styles, next } = useFormContext();

  const country = cookies["phone_country"];
  const _setCountry = (value) => {
    setCookie("phone_country", value);
  };

  const countries = getCountries();
  // console.log({ countries });

  const options = countries?.map((o) => ({
    ...o,
    key: o,
    value: o,
    label: countryNames[o],
  }));

  const setCountry = (country) => {
    console.log("set country", country);
    // if (country==null) return;
    onChange("+" + getCountryCallingCode(country));
    _setCountry(country);
  };

  const _onChange = (newValue) => {
    // if (value?.length < newValue?.length) {
    console.log({ newValue });
    if (
      country &&
      !newValue?.startsWith("+" + getCountryCallingCode(country))
    ) {
      setCountry(null);
    }
    onChange(newValue);
    // }
  };

  console.log({ country });

  return (
    <KeyboardEventHandler
      handleKeys={NEXT_QUESTION_KEYS}
      onKeyEvent={(key, e) => {
        next();
      }}
      style={{ width: "100%" }}
    >
      <ConfigProvider
        theme={{
          components: {
            Input: {
              //TODO try to figure out a way to do this a nicer way
              colorText: value
                ? styles.secondary_color
                : tinycolor(styles.secondary_color).setAlpha(0.5).toRgbString(),
            },
            Select: {
              singleItemHeightLG: 30,
            },
          },
        }}
      >
        <PhoneInput
          value={value || "+"}
          onChange={_onChange}
          placeholder="+"
          className="ant-input"
          // country={"LK"}
          defaultCountry={country}
          international={true}
          limitMaxLength={true}
          // countries={}
          style={{
            width: "100%",
            gap: "0px",
            ...getInputStyle(styles, true),
          }}
          inputComponent={MyInput}
          focusInputOnCountrySelection
          countrySelectComponent={({ onChange, value, ...props }) => (
            <CountrySelect
              {...props}
              country={country}
              setCountry={setCountry}
            />
          )}
          onCountryChange={(country) => {
            _setCountry(country);
            console.log("country", country);
            // setCountry(country);
          }}
          // style={{borderColor: 'rgba(0, 0, 0, 0.25)'}}
          {...props}
        />
      </ConfigProvider>
    </KeyboardEventHandler>
  );
};
