import React, { useState, useEffect, useRef } from "react";
import { useQueryClient } from "@tanstack/react-query";

const RepliesTab = ({
  replies,
  onReplySelect,
  isFetching,
  selectedReplyId,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const repliesPerPage = 20;
  const loadMoreTrigger = useRef(null);
  const queryClient = useQueryClient();

  const loadMoreReplies = () => {
    if (!isFetching && replies?.responses?.length > displayedReplies?.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const displayedReplies = replies?.responses?.slice(
    0,
    currentPage * repliesPerPage
  );

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          loadMoreReplies();
        }
      },
      { threshold: 1.0 }
    );

    if (loadMoreTrigger.current) {
      observer.observe(loadMoreTrigger.current);
    }

    return () => {
      if (loadMoreTrigger.current) {
        observer.unobserve(loadMoreTrigger.current);
      }
    };
  }, [currentPage, isFetching, displayedReplies]);

  //formResponses
  const onRefresh = () => {
    queryClient.invalidateQueries({ queryKey: ["formResponses"] });
  };

  function timeAgo(timestamp) {
    // Remove microseconds and create a valid Date object
    const validTimestamp = timestamp?.split(".")[0];
    const now = new Date();
    const past = new Date(validTimestamp);
    const diffInSeconds = Math.floor((now - past) / 1000);

    const timeIntervals = [
      { label: "year", seconds: 31536000 },
      { label: "month", seconds: 2592000 },
      { label: "week", seconds: 604800 },
      { label: "day", seconds: 86400 },
      { label: "hour", seconds: 3600 },
      { label: "minute", seconds: 60 },
      { label: "second", seconds: 1 },
    ];

    for (const interval of timeIntervals) {
      const count = Math.floor(diffInSeconds / interval.seconds);
      if (count > 0) {
        return count === 1
          ? `1 ${interval.label} ago`
          : `${count} ${interval.label}s ago`;
      }
    }

    return "just now";
  }

  return (
    <div className="relative mt-6 overflow-y-auto" id="card-id">
      <div className="flex items-center justify-between pb-4">
        <div>
          <div className="flex items-center gap-1 mb-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              fill="currentColor"
              aria-hidden="true"
              data-slot="icon"
              className="w-3 h-3 text-zinc-500"
            >
              <path d="M3 4.75a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM6.25 3a.75.75 0 0 0 0 1.5h7a.75.75 0 0 0 0-1.5h-7ZM6.25 7.25a.75.75 0 0 0 0 1.5h7a.75.75 0 0 0 0-1.5h-7ZM6.25 11.5a.75.75 0 0 0 0 1.5h7a.75.75 0 0 0 0-1.5h-7ZM4 12.25a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM3 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"></path>
            </svg>
            <h4 className="text-sm font-semibold">Reply History</h4>
          </div>
          <p className="text-xs text-zinc-500">
            All the reply history for this form.
          </p>
        </div>
        <button
          className="inline-flex items-center justify-center w-6 h-6 text-sm font-medium transition rounded-md whitespace-nowrap ring-offset-white focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-zinc-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-zinc-100 text-zinc-900 hover:bg-zinc-200/70 "
          onClick={onRefresh}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
            data-slot="icon"
            className="w-3 h-3"
          >
            <path
              fillRule="evenodd"
              d="M15.312 11.424a5.5 5.5 0 0 1-9.201 2.466l-.312-.311h2.433a.75.75 0 0 0 0-1.5H3.989a.75.75 0 0 0-.75.75v4.242a.75.75 0 0 0 1.5 0v-2.43l.31.31a7 7 0 0 0 11.712-3.138.75.75 0 0 0-1.449-.39Zm1.23-3.723a.75.75 0 0 0 .219-.53V2.929a.75.75 0 0 0-1.5 0V5.36l-.31-.31A7 7 0 0 0 3.239 8.188a.75.75 0 1 0 1.448.389A5.5 5.5 0 0 1 13.89 6.11l.311.31h-2.432a.75.75 0 0 0 0 1.5h4.243a.75.75 0 0 0 .53-.219Z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
      </div>

      {isFetching ? (
        <div className="flex flex-col items-center justify-center w-full gap-2 ">
          {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((i) => (
            <div className=" h-[76px] animate-pulse bg-gray-100 w-full flex flex-col rounded-lg px-2 py-2">
              <div className="flex justify-between w-full">
                <div className=" bg-gray-200 w-[80px] p-1 py-2 rounded-md animate-pulse"></div>
                <div className=" bg-gray-200 w-[80px] p-1 py-2 justify-end rounded-md animate-pulse"></div>
              </div>
              <div className=" bg-gray-200 mt-auto w-[80px] items-end p-1 py-2  rounded-md animate-pulse"></div>
            </div>
          ))}
        </div>
      ) : (
        <div className="flex flex-col w-full gap-2 overflow-y-auto">
          {displayedReplies &&
            displayedReplies.map((reply) => (
              <button
                className={`relative w-full h-[76px] px-3 py-3 text-xs text-left truncate rounded-lg  text-zinc-900 ${
                  selectedReplyId === reply?.response_id
                    ? "bg-zinc-100"
                    : "bg-zinc-50"
                } `}
                key={reply?.response_id}
                onClick={() => onReplySelect(reply?.response_id)}
              >
                <div className="flex items-center">
                  <div className="ring-1 rounded-md ring-offset-white ring-offset-[1px] ring-zinc-200 mr-2">
                    <div size="22" color="E6EBEF" className="go1973287174">
                      <p color="4D176E" size="22" className="go751257595">
                        An
                      </p>
                    </div>
                  </div>
                  <div>
                    <p className="font-medium truncate">Anonymous</p>
                    <p className="truncate text-zinc-500"></p>
                  </div>
                  <p className="bg-blue-100 text-blue-800 px-1.5 py-1 rounded-md font-medium text-xs ml-auto capitalize">
                    {reply?.type}
                  </p>
                </div>
                <p className="mt-3 ml-auto text-xs text-zinc-500">
                  {timeAgo(reply?.updated_at)}
                </p>
              </button>
            ))}
          <div ref={loadMoreTrigger} className="h-1"></div>
        </div>
      )}
    </div>
  );
};

export default RepliesTab;
