export default function Center({ children }) {
  const { props } = children;

  return (
    <div
      style={{
        width: "100vw",
        height: "95%",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        //  flex: 1,
        textAlign: props?.align ? props.align : "start",
        // backgroundColor: "red",
      }}
    >
      {children}
    </div>
  );
}
