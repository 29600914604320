import { questionAnimation } from "../../Constants/animations";
//import { useQuestionContext } from "../../Contexts/QuestionContext";
import { motion } from "framer-motion";

export const AttachmentLayout = ({ attachment }) => {
  const width = window.innerWidth;
  const height = window.innerHeight;

  const style = width > height ? { width: "80%" } : { height: "80%" };

  return (
    <motion.img
      {...questionAnimation}
      src={attachment?.href || attachment?.link}
      style={{ ...style, objectFit: "contain", borderRadius: "18px" }}
    />
  );
};
