import Center from "../Basic/Center";
import { MaxHeight } from "../Basic/MaxHeight";
import { Image, Progress, Flex } from "antd";
import {
  motion,
  useAnimationFrame,
  useMotionTemplate,
  useMotionValue,
  useTransform,
} from "framer-motion";
import { useEffect, useRef } from "react";
import { BarLoader } from "react-spinners";
import { useQuestionContext } from "../../Contexts/QuestionContext";
import { useFormContext } from "../../Contexts/FormContext";
import { useApi } from "../../Api/useApi.ts";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectForms } from "../../Redux/selectors.jsx";
import { convertFormObject } from "../MyEditor/FormObjectConfig.jsx";

export const LoadingScreen = () => {
  const { styles, setLoading, isLoading, setData, getDataFromLocal } =
    useFormContext();
  const api = useApi();

  //let { urlId: formUrlId } = useParams();
  let { urlId: formUrlId } = useParams();
  const forms = useSelector(selectForms);
  const { id } = useParams();

  // const formUrlId = "OwefOft03WtP";
  /* 
  useEffect(() => {
    // setTimeout(() => {
    //   setLoading(false);
    // }, 500);
    console.log({ formUrlId });
    if (formUrlId) {
      api.forms
        .fetchFormData(formUrlId)
        .then((res) => {
          console.log({ res });
          setData(res.data);
        })
        .then(setLoading(false));
    }
  }, [formUrlId]); */

  useEffect(() => {
    // setTimeout(() => {
    //   setLoading(false);
    // }, 500);
    if (!getDataFromLocal) {
      const form = forms.find((form) => form.id === id);
      const isPublished = form?.url_id ? true : false;
      console.log({ formUrlId });
      if (formUrlId) {
        api.forms
          .fetchFormData(formUrlId)
          .then((res) => {
            console.log({ res });
            setData(res.data);
          })
          .then(setLoading(false));
      } else if (isPublished) {
        api.forms
          .fetchFormData(form?.url_id)
          .then((res) => {
            console.log({ res });
            setData(res.data);
          })
          .then(setLoading(false));
      } else {
        const newObj = convertFormObject(form);
        console.log("newobj new : ", newObj);
        setData(newObj);
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, [formUrlId]);

  return (
    <MaxHeight>
      {/* <FormHeader /> */}
      <Center>
        <Flex vertical gap={10}>
          {/* <Image
            preview={false}
            src={"https://metana.io/wp-content/uploads/2022/07/Metana-Logo.png"}
            // height={30}
            width={"10vw"}
          /> */}
          <div
            style={{
              opacity: isLoading ? 1 : 0,
              width: "10vw",
              //   backgroundColor: "red",
              borderRadius: "5px",
              overflow: "hidden",
            }}
          >
            <BarLoader color={"rgb(30, 47, 105)"} width={"10vw"} />
          </div>
        </Flex>
      </Center>
    </MaxHeight>
  );
};
