import React from "react";
import { Modal } from "antd";
import { Row, Col } from "antd";
import { FIELD_TYPE_OPTIONS } from "../../Constants/questionTypes";
import { useFormEditorContext } from "../../Contexts/FormEditorContext";
import { randomString } from "../../Utils/string";

export const AddFieldButton = () => {
  const [open, setOpen] = React.useState(false);

  const { addField, setSelectedField } = useFormEditorContext();

  const close = () => {
    setOpen(false);
  };

  return (
    <>
      <button
        className="inline-flex items-center justify-center whitespace-nowrap font-medium ring-offset-white transition focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-zinc-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50   border border-zinc-200 bg-white hover:bg-zinc-100 hover:text-zinc-900 w-[90px]  rounded-lg text-xs h-7 px-2.5 gap-0.5"
        onClick={() => {
          setOpen(true);
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
          fill="currentColor"
          aria-hidden="true"
          data-slot="icon"
          class="h-3 w-3 -ml-0.5 mr-0.5"
        >
          <path d="M8.75 3.75a.75.75 0 0 0-1.5 0v3.5h-3.5a.75.75 0 0 0 0 1.5h3.5v3.5a.75.75 0 0 0 1.5 0v-3.5h3.5a.75.75 0 0 0 0-1.5h-3.5v-3.5Z"></path>
        </svg>
        Add field
      </button>

      <Modal
        open={open}
        title="Add field"
        // onOk={clos}
        onCancel={close}
        transitionName={""}
        footer={
          [
            //   <Button key="back" onClick={handleCancel}>
            //     Return
            //   </Button>,
            //   <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
            //     Submit
            //   </Button>,
            //   <Button
            //     key="link"
            //     href="https://google.com"
            //     type="primary"
            //     loading={loading}
            //     onClick={handleOk}
            //   >
            //     Search on Google
            //   </Button>,
          ]
        }
      >
        <div className="flex flex-wrap justify-between mx-auto">
          {Object.keys(FIELD_TYPE_OPTIONS).map((key, index) => {
            return (
              <button
                className="p-2 w-[135px]  my-1 text-xs font-semibold text-white bg-black rounded-md hover:bg-zinc-800"
                onClick={() => {
                  setOpen(false);
                  const field = {
                    id: randomString(10),
                    ...FIELD_TYPE_OPTIONS[key],
                  };
                  addField(field);
                  setSelectedField(field);
                }}
              >
                {FIELD_TYPE_OPTIONS[key].label}
              </button>
            );
          })}
        </div>
      </Modal>
    </>
  );
};
