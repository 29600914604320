import { useEffect, useState } from "react";
import { motion } from "framer-motion";

export const SwipeDetector = ({
  children,
  onScrollUp = () => {
    console.log("scroll up");
  },
  onScrollDown = () => {
    console.log("Scroll down");
  },
}) => {
  const [wheel, setWheel] = useState(null); // [up, down
  const [scrollEnabled, setScrollEnabled] = useState(true);
  // scrollEnabled
  const [scroll, setScroll] = useState(0);
  const [scrollDir, setScrollDir] = useState(null);

  useEffect(() => {
    const t = setTimeout(() => {
      // setScrollDir(wheelDir);
      // setScrollEnabled(true);
    }, 100);

    return () => clearTimeout(t);
  }, [wheel]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setScroll(wheel?.clientY);
  //   }, [50]);
  // }, [wheel]);

  const onWheel = (e) => {
    // console.log({ e });
    if (!scrollEnabled) return;
    setScrollEnabled(false);
    setWheel(e);
    if (e.deltaY > 0) {
      console.log("scroll down");
      onScrollUp();
    } else if (e.deltaY < 0) {
      console.log("scroll up");
      onScrollDown();
    }
    // onScrollDown();
  };

  // window.onwheel((e) => {
  //   if (e.deltaY > 0) {
  //     setWheelDir("down");
  //   } else {
  //     setWheelDir("up");
  //   }
  // });

  return (
    <motion.div
      onWheel={onWheel}
      // animate={{ translateY: scroll || 0 }}
    >
      {children}
    </motion.div>
  );
};
