export function convertBackToOldFormat(newObject) {
  return {
    id: newObject?.id || newObject?._id,
    name: newObject?.title || newObject?.name,
    workspace: {
      _id: newObject?.workspace?._id || newObject?.workspace?.id,
    },
    type: newObject?.type,
    owner_id: newObject?.owner_id,
    url_id: newObject?.url_id,
    created_at: newObject?.created_at,
    last_updated_at: newObject?.last_updated_at,
    theme: {
      primary_color: newObject?.theme?.primary_color,
      secondary_color: newObject?.theme?.secondary_color,
      text_color: newObject?.theme?.text_color,
      background_color: newObject?.theme?.background_color,
      font_family: newObject?.theme?.font_family,
      font: newObject?.theme?.font,
      font_weight: newObject?.theme?.font_weight,
      name: newObject?.theme?.name,
      button_color: newObject?.theme?.button_color,
      button_size: newObject?.theme?.button_size,
      button_text_color: newObject?.theme?.button_text_color,
      visibility: newObject?.theme?.visibility,
      logo: {
        _id: newObject?.theme?.logo?._id,
        type: newObject?.theme?.logo?.type,
        link: newObject?.theme?.link,
        properties: {
          description: newObject?.theme?.properties?.description,
          focal_point: {
            x: newObject?.theme?.properties?.focal_point?.x,
            y: newObject?.theme?.properties?.focal_point?.y,
          },
        },
      },
      text_size: newObject?.theme?.text_size,
      rounded_corners: newObject?.theme?.rounded_corners,
      _id: newObject?.theme?._id || newObject?.theme?.id,
      created_at: newObject?.theme?.created_at,
      updated_at: newObject?.theme?.updated_at,
      owner_id: newObject?.theme?.owner_id,
    },
    primary_color: newObject?.theme?.primary_color || newObject?.primary_color,
    text_size: newObject?.theme?.text_size || newObject?.text_size,
    secondary_color:
      newObject?.theme?.secondary_color || newObject?.secondary_color,
    welcomeScreen: {
      title:
        newObject?.welcomeScreen?.title ||
        newObject?.welcome_screens?.[0]?.title,
      type:
        newObject?.welcomeScreen?.type || newObject?.welcome_screens?.[0]?.type,

      description:
        newObject?.welcomeScreen?.description ||
        newObject?.welcome_screens?.[0]?.properties.description,

      button_text:
        newObject?.welcomeScreen?.button_text ||
        newObject?.welcome_screens?.[0]?.properties?.button_text,
      layout: {
        attachment: {
          href:
            newObject?.welcomeScreen?.layout?.attachment?.href ||
            newObject?.welcome_screens?.[0]?.layout?.attachment?.link ||
            null,
        },

        placement:
          newObject?.welcomeScreen?.layout?.placement ||
          newObject?.welcome_screens?.[0]?.layout?.placement ||
          "right",
      },
    },
    endScreen: {
      title:
        newObject?.endScreen?.title || newObject?.thankyou_screens?.[0]?.title,
      type:
        newObject?.endScreen?.type || newObject?.thankyou_screens?.[0]?.type,
      description:
        newObject?.endScreen?.description ||
        newObject?.thankyou_screens?.[0]?.properties?.description,
    },
    fields: newObject?.fields,
    settings: newObject?.settings,
    hidden: newObject?.hidden,
    links: newObject?.links,
  };
}

/* converting form object */
export function convertFormObject(oldObject) {
  return {
    title: oldObject?.name,
    workspace: {
      _id: oldObject?.workspace?._id,
    },
    type: "quiz",
    url_id: oldObject?.url_id || "",
    owner_id: "",
    created_at: "2024-07-22T11:03:27.619+00:00",
    last_updated_at: "2024-07-22T11:03:27.619+00:00",
    theme: {
      _id: oldObject?.theme?._id || oldObject?.theme?.id,
    },
    fields: oldObject?.fields,
    welcome_screens: [
      {
        id: "",
        title: oldObject?.welcomeScreen?.title,
        type: oldObject?.welcomeScreen?.type,
        properties: {
          description: oldObject.welcomeScreen.description,
          redirect_url: "https://www.google.com",
          show_button: true,
          share_icons: true,
          button_mode: "redirect",
          button_text: oldObject?.welcomeScreen?.button_text,
          text_align: "center",
        },
        layout: {
          type: "float",
          attachment: {
            link: oldObject?.welcomeScreen?.layout?.attachment?.href,
            type: "image",
          },
          placement: oldObject?.welcomeScreen?.layout?.placement,
        },
      },
    ],
    thankyou_screens: [
      {
        id: "",
        title: oldObject?.endScreen?.title,
        type: oldObject?.endScreen?.type,
        properties: {
          description: oldObject?.endScreen?.description,
          redirect_url: "https://www.google.com",
          show_button: true,
          share_icons: true,
          button_mode: "redirect",
          button_text: "Continue",
          text_align: "center",
        },
      },
    ],
    settings: {
      language: "en",
      progress_bar: "percentage",
      meta: {
        title: "Bootcamp Application Form",
        allow_indexing: true,
      },
      hide_navigation: false,
      is_public: true,
      is_trial: false,
      show_progress_bar: true,
      show_typeform_branding: true,
      are_uploads_public: false,
      show_time_to_complete: false,
      show_number_of_submissions: true,
      show_cookie_consent: false,
      show_question_number: true,
      show_key_hint_on_choices: true,
      autosave_progress: true,
      free_form_navigation: false,
    },
    hidden: [],
    links: {
      display: "",
      responses: "",
    },
  };
}
