import { createContext, useState, useContext, useEffect } from "react";
import { QUESTIONS } from "../Constants/test2.ts";
import { DATA } from "../Constants/test.js";
import { useFormContext } from "./FormContext.jsx";
import { Flex } from "antd";

const QuestionContext = createContext();

export const QuestionProvider = ({ children, question }) => {
  const currentQuestion = question;
  const [error, setError] = useState({});

  const [showNextButton, setShowNextButton] = useState(false);

  const { renderedQuestions } = useFormContext();

  // const enterPress = (isSubQuestion) => {
  //   console.log("NEXT PRESSED", { isSubQuestion });
  //   if (isSubQuestion[0] && isSubQuestion[1] < isSubQuestion[2] - 1) {
  //     console.log("SUB QUESTION NEXT");
  //     setIsSubQuestion([true, isSubQuestion[1] + 1, isSubQuestion[2]]);
  //   } else {
  //     next();
  //   }
  // };

  const initial = !renderedQuestions.includes(question?.id) || false;
  // console.log({ initial, renderedQuestions, question: question.title });

  return (
    <QuestionContext.Provider
      value={{
        currentQuestion,
        error,
        setError,
        initial,
        // setInitial,
        setShowNextButton,
        showNextButton,
      }}
    >
      {children}
    </QuestionContext.Provider>
  );
};

export const useQuestionContext = () => {
  const context = useContext(QuestionContext);
  if (!context) {
    throw new Error(
      "useQuestionContext must be used within a QuestionProvider"
    );
  }
  return context;
};
