import React, { useRef, useState } from "react";
import { AutoComplete, Input, Typography } from "antd";
import { DownOutlined } from "@ant-design/icons";
import {
  answerInputTextSize,
  getInputStyle,
  selectOptionSize,
} from "../../Constants/styles";
import { useFormContext } from "../../Contexts/FormContext";
import { useCookies } from "react-cookie";
import { useCurrentCountry } from "../../Api/useCurrentCountry";
import KeyboardEventHandler from "react-keyboard-event-handler";
import { useQuery } from "@tanstack/react-query";
import { useApi } from "../../Api/useApi.ts";

export const AutoCompleteDropdown = (props) => {
  const { field, index, country = false } = props;
  const { countryCode } = useCurrentCountry();
  const [cookies, setCookie] = useCookies([field?.id + "-open"]);
  const [value, setValue] = useState(props.value);
  const ref = useRef();

  const api = useApi();
  const { isFetching, data, isSuccess } = useQuery({
    queryKey: ["countries"],
    queryFn: () => api.utils.getCountries(),
    staleTime: Infinity,
  });

  //console.log(data?.data?.countries);
  const COUNTRY_CHOICES = data?.data?.countries;

  const editor = props?.editor;

  const isOpen = cookies[field?.id + "-open"] || false;
  const setOpen = (value) => {
    setCookie(field?.id + "-open", value);
  };

  const { styles, isSubQuestion } = useFormContext();

  /*   const options = props?.field?.properties?.choices?.map((o) => ({
    ...o,
    value: o?.label,
  }));
 */

  /*   const getCountryByCode = (countryCode) => {
    return COUNTRY_CHOICES.find((country) => country.code === countryCode);
  };
  const countryDetails = getCountryByCode(countryCode);

  const defaultCountry = countryDetails?.label; */

  const options = country
    ? COUNTRY_CHOICES?.map((o) => ({
        ...o,
        value: o?.name,
      }))
    : props?.field?.properties?.choices?.map((o) => ({
        ...o,
        value: o?.label,
      }));
  const onChange = (value) => {
    console.log("ON change");
    setOpen(true);
    setValue(value);
    // props.onChange(value);
  };

  /*  if (country && !countryCode) {
    // Render a loading state while the country code is being fetched
    return <div>Loading...</div>;
  } */

  //   const t = answerInputTextSize

  // console.log("options", options);

  return (
    <div style={{ width: "100%  " }}>
      <KeyboardEventHandler
        handleKeys={["tab"]}
        onKeyEvent={async (key, e) => {
          // setOpen(!isOpen);
          onChange();
          props.onChange(
            options?.filter(
              (option) =>
                option?.value.toUpperCase().indexOf(value.toUpperCase()) !== -1
            )[0]?.value
          );
          setTimeout(() => {
            ref?.current?.focus();
          }, 500);
        }}
        style={{ width: "100%  " }}
      >
        <AutoComplete
          ref={ref}
          onSelect={(value) => {
            setOpen(false);
            props.onChange(value);
          }}
          {...props}
          value={value}
          size="large"
          onChange={onChange}
          // defaultValue={country ? defaultCountry : null}
          onClick={() => setOpen(!isOpen)}
          // defaultActiveFirstOption
          open={isOpen}
          autoFocus={
            editor ? null : !isSubQuestion[0] || index == isSubQuestion[1]
          }
          suffixIcon={<DownOutlined />}
          variant="borderless"
          style={{ width: "100%", fontSize: null }}
          options={options}
          placeholder=""
          backfill={true}
          filterOption={(inputValue, option) =>
            option?.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          }
          // dropdownRender={(menu) => menu}
          // dropdownStyle={{color: 'red'}}
          // dropdownRender={(menu) => {
          //   return (
          //     <Typography.Text style={{ color: 'yellow' }}>
          //       {menu}
          //     </Typography.Text>
          //   );
          // }}
          optionRender={(option) => {
            return (
              <Typography.Text
                style={{
                  color: styles.secondary_color,
                  fontSize: selectOptionSize,
                }}
              >
                {option?.value}
              </Typography.Text>
            );
          }}
          defaultActiveFirstOption
          // popupMatchSelectWidth
        >
          <Input
            size={"large"}
            style={{
              width: "100%",
              ...getInputStyle(styles, true),
              fontSize: answerInputTextSize,
            }}
          />
        </AutoComplete>
      </KeyboardEventHandler>
    </div>
  );
};
