import React, { useState, useEffect } from "react";
import { Button, Typography, Flex } from "antd";
import { motion } from "framer-motion";
import { EnterOutlined } from "@ant-design/icons";
import { formButtonProps } from "../../Constants/styles";
import { useFormContext } from "../../Contexts/FormContext";
import { useFormEditorContext } from "../../Contexts/FormEditorContext";

export const FormNextButton = ({
  index,
  // onClick = () => {},
  children,
  ...rest
}) => {
  const {
    nextButtonPress,
    formInstance,
    answers,
    questions,
    currentQuestionIndex,
    formTheme,
  } = useFormContext();

  const { theme } = useFormEditorContext();
  const isFormEditor = location.pathname.includes("form-editor");
  const constTheme = isFormEditor ? theme : formTheme;

  const [isAnimating, setIsAnimating] = useState(true);

  const dynamicRadius = (() => {
    switch (constTheme?.rounded_corners) {
      case "small":
        return "0px";
      case "medium":
        return "8px";
      case "large":
        return "16px"; // Example value, adjust as needed
      default:
        return "4px"; // Default value if no match is found
    }
  })();
  // const onClick = () => {};

  //console.log("isMultiple ", answers, isMultiple);
  useEffect(() => {
    let timeout;

    const checkAnimationCondition = () => {
      const checkQuestion = questions[currentQuestionIndex];
      const question_id = checkQuestion?.id;
      const isMultiple = checkQuestion?.properties?.allow_multiple_selection;

      if (isMultiple && answers[question_id]?.length >= 1) {
        setIsAnimating(true);

        timeout = setTimeout(() => {
          setIsAnimating(false);
        }, 1000);
      } else {
        setIsAnimating(false); // Stop animation if the condition is not met
      }
    };

    checkAnimationCondition();

    return () => clearTimeout(timeout); // Clear the timeout on cleanup
  }, [questions, currentQuestionIndex, answers]);

  //console.log("isAnimating ", isAnimating);

  return (
    <motion.div
      key={`next ${index}`}
      {...formButtonProps}
      /*  transition={{
        duration: 1.5,
        repeat: Infinity,
        repeatType: "loop",
      }}
      initial={{ scale: 1 }} */
      // animate={isAnimating ? { scale: [1, 1.05, 1] } : {}}
    >
      <Flex style={{ alignItems: "center" }} gap="large">
        <Button
          size={constTheme?.button_size}
          onClick={nextButtonPress}
          style={{
            backgroundColor: constTheme?.button_color,
            color: constTheme?.button_text_color,
            // color: "white",
            // padding: "10px",
            borderRadius: dynamicRadius,
            // border: "none",
            // cursor: "pointer",
            // width: "100px",
          }}
          // type="primary"
          {...rest}
          // icon={<CheckOutlined color="black"/>}
        >
          {children}
        </Button>
        <Flex gap="small">
          <Typography.Text style={{ fontSize: 12 }}>
            {"press "}
            <Typography.Text style={{ fontSize: 12 }} strong>
              Enter
            </Typography.Text>
          </Typography.Text>
          <EnterOutlined />
        </Flex>
      </Flex>
    </motion.div>
  );
};
